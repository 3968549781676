<template>
  <div>
    <v-card-text class="pt-0">
      <v-form ref="form">
        <v-container>
          <div class="mb-4">
            <h2 class="step-title">Credit Information</h2>
          </div>
          <v-layout row wrap>
            <v-flex xs12 pb-2>
              <p>
                <a href="#" @click="dialogFCRA=1">
                  Fair Credit Reporting Act
                </a>
              </p>
              <p>I understand that the credit report is to be used for tenancy purposes only.</p>
              <v-radio-group v-model="form.creditReportUse" @change="radioChanged">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 pb-2>
              Do you understand that your business, as the end user, cannot resell the information?
              <v-radio-group v-model="form.agreeNoResell" @change="radioChanged">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 pb-2>
              Do you want the applicant to get a copy of the decision letter?
              <v-radio-group v-model="form.getDecisionLetter" @change="radioChanged">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-dialog scrollable v-model="showAlert" width="400">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          Alert
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showAlert = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <span
            v-if="!form.creditReportUse"
          ><p>Sorry, as per FCRA we are not allowed to provide credit reports for purposes other than tenancy.</p></span>
          <span
            v-if="!form.agreeNoResell"
          ><p>Sorry, as per PCI compliance we are not allowed to provide credit reports to resellers.</p></span>
          <span
              v-if="!form.getDecisionLetter"
          ><p>Applicants have  a legal right to receive a copy of the decision letter, please select ' yes'</p></span>
          If you have any questions feel free to contact us at
          <a
            href="mailto:compliance@tenantevaluation.com"
            class="white--text"
          >compliance@tenantevaluation.com</a>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogFCRA" width="800">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          Federal Fair Credit Reporting Act (FCRA)
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="dialogFCRA = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>(as amended by the Consumer Credit Reporting Reform Act of 1996)</p>
          <p>Although the FCRA primarily regulates the operations of consumer credit reporting agencies, it also affects you as a user of information. We have included a copy of the FCRA with your membership kit. We suggest that you and your employees become familiar with the following sections in particular:</p>
          <p>
            604. Permissible Purposes of Reports
            <br />607. Compliance Procedures 615. Requirement on users of consumer reports
            <br />616. Civil liability for willful noncompliance
            <br />617. Civil liability for negligent noncompliance
            <br />619. Obtaining information under false pretenses
            <br />621. Administrative Enforcement
            <br />623. Responsibilities of Furnishers of Information to Consumer Reporting Agencies
            <br />628. Disposal of Records
          </p>
          <p>Each of these sections is of direct consequence to users who obtain reports on consumers. !</p>
          <p>As directed by the law, credit reports may be issued only if they are to be used for extending credit, review or collection of an account, employment purposes, underwriting insurance or in connection with some other legitimate business transaction such as in investment, partnership, etc. It is imperative that you identify each request for a report to be used for employment purposes when such report is ordered. Additional state laws may also impact your usage of reports for employment purposes.!</p>
          <p>We strongly endorse the letter and spirit of the Federal Fair Credit Reporting Act. We believe that this law and similar state laws recognize and preserve the delicate balance between the rights of the consumer and the legitimate needs of commerce.!</p>
          <p>In addition to the Federal Fair Credit Reporting Act, other federal and state laws addressing such topics as computer crime and unauthorized access to protected databases have also been enacted. As a prospective user of consumer reports, we expect that you and your staff will comply with all relevant federal statutes and the statutes and regulations of the states in which you operate.</p>
          <p>We support consumer reporting legislation that will assure fair and equitable treatment for all consumers and users of credit information.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="dialogFCRA = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";

export default {
  name: "CreditInformationStep",
  props: {
    step: Number
  },
  data() {
    return {
      showAlert: false,
      dialogFCRA: false,
      form: {
        creditReportUse: null,
        agreeNoResell: null,
        getDecisionLetter: null
      }
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    })
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    radioChanged(val) {
      if (!val) {
        this.showAlert = true;
      }
    },
    submitForm() {
      const _this = this;
      if (!this.form.creditReportUse) {
        this.showAlert = true;
        return;
      }
      if (!this.form.agreeNoResell) {
        this.showAlert = true;
        return;
      }
      if (!this.form.getDecisionLetter) {
        this.showAlert = true;
        return;
      }
      if (!this.form.getDecisionLetter == null) {
        return this.alertError("Please complete the required fields.");
      }
      this.currentApp.stepUsers = false
      this.currentApp.stepDocuments = false
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)
      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
          _this.$events.$emit("success", {
            step: _this.step
          });
        })
        .catch(e => {
          //this.loginFailed(e.error || 'An error occurred while processing your request.')
          // eslint-disable-next-line
          console.error(e);
        });
    },
    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null) {
          this.form[key] = this.currentApp[key];
        }
      }
    }
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm();
  }
};
</script>
<style>
</style>
