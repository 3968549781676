<template>
  <div>
    <v-card-text class="pt-0">
      <v-form ref="form">
        <v-container>
          <div class="mb-4">
            <h2 class="step-title">Compliance</h2>
          </div>

          <div class="mb-4">
            <h2
              class="property-title"
              style="padding-left:0px!important;"
            >California Civil Code Section 1785.14(a)</h2>
            <div class="grey--text"></div>
          </div>
          <v-layout row wrap>
            <v-flex xs12 pb-2>
              <strong>Is the end user a retail seller?</strong>
              <v-radio-group
                v-model="form.caComplianceInfo.retailSeller"
                @change="endUserRetailSellerChanged"
                :autofocus="$isDesktop()"
              >
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 mb-3>
              <p>
                <a href="#" @click="showDialog=true">California civil code</a>
              </p>In compliance with Section 1785.14(a) of the California Civil Code, Property/Company here by certifies to Consumer Reporting Agency as follows:
            </v-flex>
            <v-flex xs12 mb-5>
              End User (Property or Management Team)
              <span class="green--text">
                <strong v-if="form.caComplianceInfo.retailSeller==null">____</strong>
                <template v-else>
                  <strong v-if="form.caComplianceInfo.retailSeller">IS</strong>
                  <strong v-if="!form.caComplianceInfo.retailSeller">IS NOT</strong>
                </template>
              </span> a retail seller, as defined in Section 1802.3 of the California Civil Code (“Retail Seller”) and issues credit to consumers who appear in person on the basis of applications for credit submitted in person (“Point of Sale”).
            </v-flex>

            <v-flex xs12 pb-2>
              <strong>Do you, Qualified Subscriber (Property or Management Team), issue credit to consumers who appear in person on the basis of an application for credit submitted in person?</strong>
              <v-radio-group
                  @change="issuesCreditChanged"
                  v-model="form.caComplianceInfo.issuesCredit">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs12 mb-3>
              <v-checkbox
                color="primary"
                label="I agree. My signature will be automatically added at the end of this process to reflect my authorization."
                v-model="form.caComplianceInfo.agreeCommonSignature"
                :error="!form.caComplianceInfo.agreeCommonSignature"
              ></v-checkbox>
            </v-flex>
          </v-layout>

          <div class="mb-4" style="padding-top: 40px;">
            <h2
              class="property-title"
              style="padding-left:0px!important;"
            >Vermont Fair Credit Reporting Statute 9 V.S.A. § 2480e</h2>
            <div class="grey--text"></div>
          </div>
          <v-layout row wrap>
            <v-flex xs12 mb-3>
              Do you agree and understand the
              <a
                href="#"
                @click="showDialogVermont=true"
              >Vermont Credit reporting statute</a>?
              <v-radio-group
                v-model="form.vtComplianceInfo.understandVCRS"
                @change="understandVCRSChanged"
              >
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs12 mb-3>
              <v-checkbox
                color="primary"
                label="I agree. My signature will be automatically added at the end of this process to reflect my authorization."
                v-model="form.vtComplianceInfo.agreeCommonSignature"
                :error="!form.vtComplianceInfo.agreeCommonSignature"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-dialog scrollable v-model="showAlert" width="400">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          Alert
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showAlert = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Sorry, as per PCI compliance we are not allowed to provide credit reports to resellers. If you have any questions feel free to contact us at
          <a
            href="mailto:compliance@tenantevaluation.com"
            class="white--text"
          >compliance@tenantevaluation.com</a>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="showIssuesCreditAlert" width="400">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          Alert
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showIssuesCreditAlert = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Sorry, as per FCRA we are not allowed to provide credit reports for purposes other than a tenancy. Sorry. If you have any questions feel free to contact us at
          <a
            href="mailto:compliance@tenantevaluation.com"
            class="white--text"
          >compliance@tenantevaluation.com</a>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="showDialog" width="800">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          California Civil Code Section 1785.14(a)
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>Provisions of the California Consumer Credit Reporting Agencies Act, as amended effective July 1, 1998, will impact the provision of consumer reports to Qualified Subscriber under the following circumstances: (a) If Qualified Subscriber is a &ldquo;retail seller&rdquo; (defined in part by California law as &ldquo;a person engaged in the business of selling goods or services to retail buyers&rdquo;) and is selling to a &ldquo;retail buyer&rdquo; (defined as &ldquo;a person who buys goods or obtains services from a retail seller in a retail installment sale and not principally for the purpose of resale&rdquo;) and a consumer about whom Qualified Subscriber is inquiring is applying, (b) In person, and (c) for credit. Under the foregoing circumstances, Equifax, before delivering a consumer report to Qualified Subscriber, must match at least three (3) items of a consumer&rsquo;s identification within the file maintained by Equifax with the information provided to Equifax by Qualified Subscriber in connection with the in-person credit transaction.</p>
          <p>Compliance with this law further includes Qualified Subscriber&rsquo;s inspection of the photo identification of each consumer who applies for in-person credit, mailing extensions of credit to consumers responding to a mail solicitation at specified addresses, taking special actions regarding a consumer&rsquo;s presentment of a police report regarding fraud, and acknowledging consumer demands for reinvestigations within certain time frames.</p>
          <p>If Qualified Subscriber designated in Paragraph 8 of the Terms and Conditions that it is a &ldquo;retail seller,&rdquo; Qualified Subscriber certifies that it will instruct its employees to inspect a photo identification of the consumer at the time an application is submitted in person. If Qualified Subscriber is not currently, but subsequently becomes a &ldquo;retail seller,&rdquo; Qualified Subscriber agrees to provide written notice to Equifax prior to ordering credit reports in connection with an in-person credit transaction, and agrees to comply with the requirements of the California law as outlined in this Exhibit, and with the specific certifications set forth herein.</p>
          <p>Qualified Subscriber certifies that, as a &ldquo;retail seller,&rdquo; it will either (a) acquire a new Qualified Subscriber number for use in processing consumer report inquiries that result from in person credit applications covered by California law, with the understanding that all inquiries using this new Qualified Subscriber number will require that Qualified Subscriber supply at least three items of identifying information from the applicant; or (b) contact Qualified Subscriber&rsquo;s Equifax sales representative to ensure that Qualified Subscriber&rsquo;s existing number is properly coded for these transactions.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog scrollable v-model="showDialogVermont" width="800">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          Vermont Fair Credit Reporting Statute 9 V.S.A. § 2480e
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialogVermont = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>The undersigned, acknowledges that it subscribes to receive various information services from Equifax Information Services LLC (&ldquo;Equifax&rdquo;) in accordance with the Vermont Fair Credit Reporting Statute, 9 V.S.A. &sect; 2480e (1999), as amended (the &ldquo;VFCRA&rdquo;) and the Federal Fair Credit Reporting Act, 15, U.S.C. 1681 et. Seq., as amended (the &ldquo;FCRA&rdquo;) and its other state law counterparts. In connection with Qualified Subscriber&rsquo;s continued use of Equifax information services in relation to Vermont consumers, Qualified Subscriber hereby certifies as follows:</p>
          <p>Vermont Certification. Qualified Subscriber certifies that it will comply with applicable provisions under Vermont law. In particular, Qualified Subscriber certifies that it will order information services relating to Vermont residents, that are credit reports as defined by the VFCRA, only after Qualified Subscriber has received prior consumer consent in accordance with VFCRA &sect; 2480e and applicable Vermont Rules. Qualified Subscriber further certifies that the attached copy of &sect; 2480e of the Vermont Fair Credit Reporting Statute was received from Equifax.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showDialogVermont = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import Validator from "../../libs/validator";
import StatesJson from "../../states_hash.json";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  name: "ComplianceStep",
  props: {
    step: Number
  },
  data() {
    const validator = new Validator();
    return {
      states: StatesJson,
      showAlert: false,
      showIssuesCreditAlert: false,
      showDialog: false,
      showDialogVermont: false,
      showMenu: false,
      form: {
        caComplianceInfo: {
          retailSeller: false,
          issuesCredit: false,
          agreeCommonSignature: null
        },

        vtComplianceInfo: {
          understandVCRS: null,
          agreeCommonSignature: null
        }
      },
      rules: {
        required: [validator.rules.required()],
        zipcode: [validator.rules.zipcode()],
        email: [validator.rules.email()],
        phone: [validator.rules.phone()]
      }
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    })
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    endUserRetailSellerChanged(val) {
      if (val) {
        this.showAlert = true;
      }
    },
    issuesCreditChanged(val) {
      if (val) {
        this.showAlert = true;
      }
    },
    understandVCRSChanged(val) {
      if (!val) {
        this.showAlert = true;
      }
    },
    submitForm() {
      const _this = this;
      if (this.form.caComplianceInfo.issuesCredit) {
        this.showIssuesCreditAlert = true;
        return;
      }
      if (this.form.caComplianceInfo.retailSeller) {
        this.showAlert = true;
        return;
      }
      if (!this.form.caComplianceInfo.agreeCommonSignature) {
        return this.alertError("You must agree the terms of California Civil Code.");
      }

      if (!this.form.vtComplianceInfo.understandVCRS) {
        this.showAlert = true;
        return;
      }
      if (!this.$refs.form.validate()) {
        return this.alertError("Please complete the required fields.");
      }
      if (!this.form["vtComplianceInfo"]["agreeCommonSignature"]) {
        return this.alertError("You must agree the terms of Vermont Credit reporting statute.");
      }

      this.currentApp.stepUsers = false
      this.currentApp.stepDocuments = false
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)
      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
          _this.$events.$emit("success", {
            step: _this.step
          });
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    fillForm() {
      for (let key in this.form.caComplianceInfo) {
        if (this.currentApp.caComplianceInfo[key] != null) {
          this.form.caComplianceInfo[key] = this.currentApp.caComplianceInfo[
            key
          ];
        }
      }

      if (this.currentApp["vtComplianceInfo"]["understandVCRS"] != null) {
        this.form["vtComplianceInfo"]["understandVCRS"] = this.currentApp[
          "vtComplianceInfo"
        ]["understandVCRS"];
      }
      for (let key in this.form["vtComplianceInfo"]) {
        if (this.currentApp["vtComplianceInfo"][key]) {
          this.form["vtComplianceInfo"][key] = this.currentApp[
            "vtComplianceInfo"
          ][key];
        }
      }
    }
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm();
  }
};
</script>
<style>
</style>
