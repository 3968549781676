var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: true },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogViewer" },
            [
              _c(
                "v-toolbar",
                { attrs: { fixed: "" } },
                [
                  _c("v-toolbar-title", [_vm._v("File Preview")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _c("iframe", { attrs: { src: _vm.viewData } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pt-0" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _c("h2", { staticClass: "step-title" }, [
                      _vm._v("Documents"),
                    ]),
                  ]),
                  _vm.isLoading
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "text-xs-center": "",
                            "pb-5": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _vm.extraDocumentLimit
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c("h3", [
                                    _vm._v("Basic document collection"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          !_vm.extraDocumentLimit
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c(
                                    "h3",
                                    { staticStyle: { color: "#2196F3" } },
                                    [_vm._v("Document collection")]
                                  ),
                                  _c("h4", [
                                    _vm._v(
                                      "Tenant Evaluton will collect the following documents for all type of applicants:"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-3": "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "layout-included",
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    "justify-space-between": "",
                                    "px-3": "",
                                    "py-3": "",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "doc-title" }, [
                                    _c("strong", [
                                      _vm._v("Photo Identification"),
                                    ]),
                                    _vm._v(
                                      " (US Driver's License or Passport)*"
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "doc-required text-muted" },
                                    [_vm._v("required")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-3": "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "layout-included",
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    "justify-space-between": "",
                                    "px-3": "",
                                    "py-3": "",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "doc-title" }, [
                                    _c("strong", [
                                      _vm._v("Marriage Certificate"),
                                    ]),
                                    _vm._v(
                                      " (If applicable and if married with different last names)*"
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "doc-required text-muted" },
                                    [_vm._v("required")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-3": "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "layout-included",
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    "justify-space-between": "",
                                    "px-3": "",
                                    "py-3": "",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "doc-title" }, [
                                    _c("strong", [_vm._v("Pet Photo")]),
                                    _vm._v(" (If applicable)"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "doc-required text-muted" },
                                    [_vm._v("required")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.currentApp.qaLetter
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "layout-included",
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                        "justify-space-between": "",
                                        "px-3": "",
                                        "py-3": "",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "doc-title" }, [
                                        _c("strong", [
                                          _vm._v(
                                            "Quick-approve letter customization"
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", { staticClass: "qa-text" }, [
                                          _c("p", [
                                            _vm._v(
                                              "You have selected that you would like a customized approval letter to match your own branding, please send a copy of the custom approval letter to compliance@tenantevaluation.com"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      false
                                        ? _c("UploadButton", {
                                            attrs: {
                                              color: _vm.form.qaLetterDocUrl
                                                ? "black"
                                                : "primary",
                                              labelClass: "white--text",
                                              large: "",
                                              depressed: "",
                                              title: _vm.form.qaLetterDocUrl
                                                ? _vm.form.qaLetterDocUrl
                                                    .split(/(\\|\/)/g)
                                                    .pop()
                                                    .split(/\?/g)
                                                    .shift()
                                                : "Upload Now",
                                              multiple: false,
                                              accept: ".doc,.docx,.pdf",
                                              done: _vm.uploadQaLetterDoc,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.isUploading.qaLetter
                                        ? _c("v-btn", {
                                            class: { "blue--text": true },
                                            attrs: {
                                              large: "",
                                              depressed: "",
                                              color: "primary",
                                              loading: true,
                                              disabled: true,
                                            },
                                          })
                                        : _vm._e(),
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                flat: "",
                                                icon: "",
                                                color: "grey",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewDoc("qaLetter")
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "rotate" },
                                                [_vm._v("visibility")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                flat: "",
                                                icon: "",
                                                color: "grey",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeDoc(
                                                    "qaLetter"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "rotate" },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentApp.deluxeFee
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "layout-included",
                                      attrs: {
                                        "d-flex": "",
                                        "align-center": "",
                                        "justify-space-between": "",
                                        "px-3": "",
                                        "py-3": "",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "doc-title" }, [
                                        _c("strong", [
                                          _vm._v("Deluxe Application"),
                                        ]),
                                        _c("br"),
                                        _c("span", { staticClass: "qa-text" }, [
                                          _c("p", [
                                            _vm._v(
                                              "You have selected that you would like to customize your application process with your own rules and regulations, and community forms on the proposal, please send the documents to compliance@tenantevaluation.com"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      false
                                        ? _c("UploadButton", {
                                            attrs: {
                                              color: _vm.form.deluxeFeeDocUrl
                                                ? "black"
                                                : "primary",
                                              labelClass: "white--text",
                                              large: "",
                                              depressed: "",
                                              title: _vm.form.deluxeFeeDocUrl
                                                ? _vm.form.deluxeFeeDocUrl
                                                    .split(/(\\|\/)/g)
                                                    .pop()
                                                    .split(/\?/g)
                                                    .shift()
                                                : "Upload Now",
                                              multiple: false,
                                              accept: ".doc,.docx,.pdf",
                                              loading: true,
                                              done: _vm.uploadDeluxeFeeDoc,
                                            },
                                          })
                                        : _vm._e(),
                                      false
                                        ? _c("v-btn", {
                                            class: { "blue--text": true },
                                            attrs: {
                                              large: "",
                                              depressed: "",
                                              color: "primary",
                                              loading: true,
                                              disabled: true,
                                            },
                                          })
                                        : _vm._e(),
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                flat: "",
                                                icon: "",
                                                color: "grey",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewDoc(
                                                    "deluxeFee"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "rotate" },
                                                [_vm._v("visibility")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                flat: "",
                                                icon: "",
                                                color: "grey",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeDoc(
                                                    "deluxeFee"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "rotate" },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "font-size": "14px" },
                              attrs: { xs12: "", "pb-2": "" },
                            },
                            [
                              _vm._v(
                                "* Please note Tenant Evaluation will collect the Photo ID as a mandatory requirement to confirm the identity and Marriage Certificate (If applicable and if married with different last names) to verify application fees."
                              ),
                            ]
                          ),
                          _vm.extraDocumentLimit
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mt-2": "", "mb-3": "" } },
                                [
                                  _c(
                                    "h3",
                                    { staticStyle: { color: "#2196F3" } },
                                    [
                                      _vm._v(
                                        "You can collect up to " +
                                          _vm._s(_vm.extraDocumentLimit) +
                                          " extra documents"
                                      ),
                                    ]
                                  ),
                                  _c("h4", { staticClass: "mb-3" }, [
                                    _vm._v("Select additional documents"),
                                  ]),
                                  _c("span", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "Please list the documents you want Tenant Evaluation to collect from the applicant on your behalf."
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.selectedDocs,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    flex: "",
                                                    "blue--text": "",
                                                    "align-center": "",
                                                    "justify-space-between": "",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Document No." +
                                                        _vm._s(index + 1)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        icon: "",
                                                        color: "grey",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDocument(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "rotate",
                                                        },
                                                        [_vm._v("delete")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "pr-3",
                                                      attrs: { xs6: "" },
                                                    },
                                                    [
                                                      _vm.selectedDocs[index]
                                                        .standardDocumentId !=
                                                      -1
                                                        ? [
                                                            _c("v-select", {
                                                              attrs: {
                                                                autofocus: true,
                                                                "menu-props": {
                                                                  contentClass:
                                                                    "select-doctype",
                                                                },
                                                                "item-value":
                                                                  "id",
                                                                "item-text":
                                                                  "description",
                                                                items:
                                                                  _vm.selectDocuments,
                                                                placeholder:
                                                                  "Select type of document",
                                                                "single-line":
                                                                  "",
                                                                box: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.docChange(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedDocs[
                                                                    index
                                                                  ]
                                                                    .standardDocumentId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .selectedDocs[
                                                                        index
                                                                      ],
                                                                      "standardDocumentId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "selectedDocs[index].standardDocumentId",
                                                              },
                                                            }),
                                                          ]
                                                        : [
                                                            _c("v-text-field", {
                                                              ref: "field",
                                                              refInFor: true,
                                                              attrs: {
                                                                "browser-autocomplete":
                                                                  "off",
                                                                autofocus: true,
                                                                label:
                                                                  "Document Name",
                                                                placeholder:
                                                                  "Please type the document name",
                                                                required: "",
                                                                box: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedDocs[
                                                                    index
                                                                  ].description,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .selectedDocs[
                                                                        index
                                                                      ],
                                                                      "description",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "selectedDocs[index].description",
                                                              },
                                                            }),
                                                          ],
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "pl-3",
                                                      attrs: { xs6: "" },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm
                                                              .designationTypes[
                                                              index
                                                            ],
                                                          autofocus: false,
                                                          label: "Applies To",
                                                          box: "",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.designationChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedDocs[
                                                              index
                                                            ].designation,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.selectedDocs[
                                                                index
                                                              ],
                                                              "designation",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selectedDocs[index].designation",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.extraDocumentLimit &&
                                  _vm.differentDocuments <
                                    _vm.extraDocumentLimit
                                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple",
                                              },
                                            ],
                                            staticClass:
                                              "d-inline-block pr-3 rounded",
                                            on: { click: _vm.addNewDocument },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  fab: "",
                                                  small: "",
                                                  dark: "",
                                                  depressed: "",
                                                },
                                              },
                                              [_c("v-icon", [_vm._v("add")])],
                                              1
                                            ),
                                            _c("a", { staticClass: "ml-1" }, [
                                              _vm._v("Add document"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "600" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v("\n        Alert\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showAlert = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("span", [
                  _vm._v(
                    "\n          The Income report directly connects to an applicants' bank account, providing a real-time reflection of the applicants' financial standing. The report includes a compilation of all deposit history, current balance history and assigns a confidence score for ranking. The security of applicants' data is our top priority. As such, only information necessary for approval is displayed on the Income Report.\n          "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v("\n          For:\n          "),
                  _c("br"),
                  _c("ul", [
                    _c("li", [_vm._v("International Applicants")]),
                    _c("li", [
                      _vm._v("Applicants without an American Bank Account"),
                    ]),
                    _c("li", [
                      _vm._v(
                        "Applicants who deny authorization access to their bank accounts"
                      ),
                    ]),
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n          Tenant Evaluation will alternatively collect paperwork that demonstrates an equivalent representation of the applicant's financial standing. This will include Paystubs, 1099 forms, Bank Statements, etc.\n        "
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showAlert = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }