var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { staticClass: "pt-0" },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-container",
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("h2", { staticClass: "step-title" }, [
                  _vm._v("Community Information"),
                ]),
              ]),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _vm.isBlacklisted
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            value: true,
                            color: "warning",
                            icon: "warning",
                            outline: "",
                          },
                        },
                        [
                          _vm._v(
                            "\n          Some of the Information entered above has been flagged down on the Credit Bureau's Blacklist! In accordance to FCRA regulations, our team is required to conduct further research. A Sale representative will contact you as soon as possible with additional information. Thank you!\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          label: "Community Name",
                          rules: _vm.rules.required,
                          autofocus: _vm.$isDesktop(),
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.propertyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "propertyName", $$v)
                          },
                          expression: "form.propertyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "browser-autocomplete": "off",
                          items: _vm.states,
                          "item-text": "name",
                          "item-value": "abbr",
                          label: "State where incorportated",
                          "no-data-text": "State not found.",
                          "persistent-hint": "",
                          rules: _vm.rules.required,
                          box: "",
                        },
                        model: {
                          value: _vm.form.businessOrigin,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "businessOrigin", $$v)
                          },
                          expression: "form.businessOrigin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.entityTypes,
                          label: "Business / Entity Type",
                          rules: _vm.rules.required,
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.entityType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "entityType", $$v)
                          },
                          expression: "form.entityType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          label: "FEI / EIN number*",
                          rules: _vm.rules.einNumber,
                          required: "",
                          box: "",
                        },
                        on: { change: _vm.einChange },
                        model: {
                          value: _vm.form.einNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "einNumber", $$v)
                          },
                          expression: "form.einNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "span",
                      { staticClass: "v-messages theme--light small-hint" },
                      [
                        _c("strong", [_vm._v("FEI / EIN number*")]),
                        _vm._v(
                          " Federal Employer Identification No / Employer Identification No. You can locate your FEI / EIN on your confirmation letter from the IRS, tax returns, payroll paperwork, etc."
                        ),
                      ]
                    ),
                  ]),
                  _vm.onAutoAddress
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("vuetify-google-autocomplete", {
                            ref: "form.address",
                            attrs: {
                              id: "map",
                              classname: "form-control",
                              placeholder: "Please type your address",
                              country: "us",
                              label: "Address",
                              required: "",
                              box: "",
                            },
                            on: { placechanged: _vm.getAddressData },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.onAutoAddress
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Mailing address",
                                rules: _vm.rules.required,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.street,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "street", $$v)
                                },
                                expression: "form.street",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "City",
                                rules: _vm.rules.required,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "city", $$v)
                                },
                                expression: "form.city",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                "browser-autocomplete": "off",
                                items: _vm.states,
                                "item-text": "name",
                                "item-value": "abbr",
                                label: "State",
                                "no-data-text": "State not found.",
                                "persistent-hint": "",
                                rules: _vm.rules.required,
                                box: "",
                              },
                              model: {
                                value: _vm.form.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "state", $$v)
                                },
                                expression: "form.state",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Zip Code",
                                rules: _vm.rules.zipcode,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.zipcode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "zipcode", $$v)
                                },
                                expression: "form.zipcode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  !_vm.isGoogleAutocompleteDisabled
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.onAutoAddress
                            ? [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link-form",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        _vm.onAutoAddress = false
                                      },
                                    },
                                  },
                                  [_vm._v("or enter address manually")]
                                ),
                              ]
                            : [
                                _c(
                                  "a",
                                  {
                                    staticClass: "link-form",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        _vm.onAutoAddress = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "or enter address using google suggestions"
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          label: "Community main phone number",
                          rules: _vm.rules.phone,
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.propertyPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "propertyPhone", $$v)
                          },
                          expression: "form.propertyPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          type: "number",
                          label: "Community phone extension (optional)",
                          box: "",
                        },
                        model: {
                          value: _vm.form.propertyPhoneExt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "propertyPhoneExt", $$v)
                          },
                          expression: "form.propertyPhoneExt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          "browser-autocomplete": "off",
                          rules: _vm.rules.required,
                          label: "Years in business*",
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.businessYears,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "businessYears", $$v)
                          },
                          expression: "form.businessYears",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          "browser-autocomplete": "off",
                          rules: _vm.rules.required,
                          label: "Number of units*",
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.unitsNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "unitsNumber", $$v)
                          },
                          expression: "form.unitsNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          label: "Website (optional)",
                          box: "",
                        },
                        model: {
                          value: _vm.form.businessWebsite,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "businessWebsite", $$v)
                          },
                          expression: "form.businessWebsite",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "browser-autocomplete": "off",
                          label: "Entity Id (optional)",
                          rules: _vm.rules.entityId,
                          box: "",
                        },
                        model: {
                          value: _vm.form.entityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "entityId", $$v)
                          },
                          expression: "form.entityId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "span",
                      { staticClass: "v-messages theme--light small-hint" },
                      [
                        _c("strong", [_vm._v("Entity ID")]),
                        _vm._v(
                          " You can add any internal code your organization uses to track this community for accounting purposes. This code will be reflected on the community reports."
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
              _vm.isLoading
                ? _c(
                    "v-layout",
                    {
                      staticClass: "layout-sunbiz",
                      attrs: {
                        row: "",
                        wrap: "",
                        "text-xs-center": "",
                        "pb-5": "",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "border-sunbiz" },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-2": "", "pt-2": "" } },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("../../assets/upload-doc.svg"),
                                  height: "40",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-2": "", "pt-2": "" } },
                            [
                              _c("strong", [
                                _vm._v(
                                  "Upload a copy of the articles of incorporation, in addition send a copy of you current annual report or EIN number issue letter."
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-2": "", "pt-2": "" } },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-2": "", "pt-2": "" } },
                            [
                              _vm._v(
                                "\n            Please wait, we are uploading articles of incorporation and annual report of your company\n          "
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-2": "", "pt-2": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    flat: "",
                                    small: "",
                                    outline: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelDocs()
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    {
                      staticClass: "layout-sunbiz",
                      attrs: { "text-xs-center": "", row: "", wrap: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "border-sunbiz" },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-2": "", "pt-2": "" } },
                            [
                              _c("img", {
                                staticClass: "mr-2",
                                attrs: {
                                  src: require("../../assets/upload-doc.svg"),
                                  height: "40",
                                },
                              }),
                            ]
                          ),
                          _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                            _c("strong", [
                              _vm._v(
                                "Articles of Incorporation and Annual Report / EIN number issue letter"
                              ),
                            ]),
                          ]),
                          !(_vm.form.businessOrigin == "FL")
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-4": "" } },
                                  [
                                    _vm._v(
                                      "Please upload a copy of the articles of incorporation and annual report from the Division of Corporations of the State where incorportated."
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm.form.businessOrigin == "FL" && !_vm.hasDocs
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-2": "" } },
                                  [
                                    _vm._v(
                                      "Please upload a copy of the articles of incorporation and annual report from the Division of Corporations of the State where incorporated. "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-4": "" } },
                                  [
                                    _vm._v(
                                      "You can get a copy of the articles of incorporation and annual report from the following link or click Download button"
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-4": "" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "http://search.sunbiz.org",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("search.sunbiz.org")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-4": "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          small: "",
                                          outline: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkDocs()
                                          },
                                        },
                                      },
                                      [_vm._v("Download")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.form.businessOrigin == "FL" && _vm.hasDocs
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-2": "" } },
                                  [
                                    _vm._v(
                                      "We have detected the company was created in Florida and through the EIN number we have automatically attached the Annual Report and Articles of Incorporation."
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "mb-4": "" } },
                                  [
                                    _vm._v(
                                      "Please confirm all the information is correct."
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm.annualReportDocs.length
                            ? [
                                _c(
                                  "v-flex",
                                  {
                                    key: _vm.annualReportDocs[0].documentId,
                                    attrs: {
                                      xs12: "",
                                      "mt-3": "",
                                      "mb-4": "",
                                      "text-xs-center": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "mr-3" },
                                      [
                                        _c("v-icon", [_vm._v("attachment")]),
                                        _vm._v(
                                          "Annual Report / EIN number issue letter\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          small: "",
                                          outline: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewDoc(
                                              _vm.annualReportDocs[0].documentId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("View")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          small: "",
                                          outline: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeDoc(
                                              _vm.annualReportDocs[0].documentId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Remove")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.incorporationDocs.length
                            ? [
                                _c(
                                  "v-flex",
                                  {
                                    key: _vm.incorporationDocs[0].documentId,
                                    attrs: {
                                      xs12: "",
                                      "mt-3": "",
                                      "mb-4": "",
                                      "text-xs-center": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "h3",
                                      { staticClass: "mr-3" },
                                      [
                                        _c("v-icon", [_vm._v("attachment")]),
                                        _vm._v(
                                          "Articles of Incorporation\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          small: "",
                                          outline: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewDoc(
                                              _vm.incorporationDocs[0]
                                                .documentId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("View")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          small: "",
                                          outline: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeDoc(
                                              _vm.incorporationDocs[0]
                                                .documentId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Remove")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          !_vm.annualReportDocs.length
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "pb-4": "" } },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "Annual Report / EIN number issue letter"
                                    ),
                                  ]),
                                  !_vm.isUploading.annual_report
                                    ? _c("UploadButton", {
                                        attrs: {
                                          large: "",
                                          depressed: "",
                                          title: "Upload",
                                          multiple: false,
                                          done: _vm.uploadAnnualReport,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.isUploading.annual_report
                                    ? _c("v-btn", {
                                        class: { "blue--text": true },
                                        attrs: {
                                          large: "",
                                          depressed: "",
                                          color: "primary",
                                          loading: true,
                                          disabled: true,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.incorporationDocs.length
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "pb-5": "" } },
                                [
                                  _c("h3", [
                                    _vm._v("Articles Of Incorporation"),
                                  ]),
                                  !_vm.isUploading.incorporation
                                    ? _c("UploadButton", {
                                        attrs: {
                                          large: "",
                                          depressed: "",
                                          title: "Upload",
                                          multiple: false,
                                          done: _vm.uploadArticlesIncorporation,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.isUploading.incorporation
                                    ? _c("v-btn", {
                                        class: { "blue--text": true },
                                        attrs: {
                                          large: "",
                                          depressed: "",
                                          color: "primary",
                                          loading: true,
                                          disabled: true,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { "pt-4": "", row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "pb-3": "" } }, [
                    _c("strong", [
                      _vm._v(
                        "Please add the name, email, and position of the authorized individuals who will access the account*"
                      ),
                    ]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-2": "", "mb-3": "" } },
                    [
                      _vm._l(_vm.form.users, function (item, index) {
                        return _c(
                          "v-layout",
                          { key: index, attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      flex: "",
                                      "blue--text": "",
                                      "align-center": "",
                                      "justify-space-between": "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("User No" + _vm._s(index + 1)),
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          flat: "",
                                          icon: "",
                                          color: "grey",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeUser(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "rotate" },
                                          [_vm._v("delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "pr-3",
                                attrs: { xs12: "", sm6: "" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "browser-autocomplete": "off",
                                    label: "Complete name",
                                    rules: _vm.rules.required,
                                    required: "",
                                    box: "",
                                  },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "pr-3",
                                attrs: { xs12: "", sm6: "" },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.userTypes,
                                    label: "Position",
                                    rules: _vm.rules.required,
                                    required: "",
                                    box: "",
                                  },
                                  model: {
                                    value: item.position,
                                    callback: function ($$v) {
                                      _vm.$set(item, "position", $$v)
                                    },
                                    expression: "item.position",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "pr-3",
                                attrs: { xs12: "", sm6: "" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "browser-autocomplete": "off",
                                    label: "Email",
                                    rules: _vm.rules.email,
                                    required: "",
                                    box: "",
                                  },
                                  model: {
                                    value: item.email,
                                    callback: function ($$v) {
                                      _vm.$set(item, "email", $$v)
                                    },
                                    expression: "item.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "pr-3",
                                attrs: { xs12: "", sm6: "" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "browser-autocomplete": "off",
                                    label: "Phone number",
                                    rules: _vm.rules.phone,
                                    required: "",
                                    box: "",
                                  },
                                  model: {
                                    value: item.phone,
                                    callback: function ($$v) {
                                      _vm.$set(item, "phone", $$v)
                                    },
                                    expression: "item.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.totalUsers < 5
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" },
                                ],
                                staticClass: "d-inline-block pr-3 rounded",
                                on: { click: _vm.addNewUser },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      fab: "",
                                      small: "",
                                      dark: "",
                                      depressed: "",
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("add")])],
                                  1
                                ),
                                _c("a", { staticClass: "ml-1" }, [
                                  _vm._v("Add User"),
                                ]),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "font-size": "14px", padding: "30px 0px" },
                      attrs: { xs12: "", "pb-2": "" },
                    },
                    [
                      _vm._v(
                        "*Please be informed that users are not allowed to share accounts for security reasons. If there is a change, please make sure to notify Tenant Evaluation within 1 to 3 business days."
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: true },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogViewer" },
            [
              _c(
                "v-toolbar",
                { attrs: { fixed: "" } },
                [
                  _c("v-toolbar-title", [_vm._v("File Preview")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.view
                  ? _c("iframe", { attrs: { src: _vm.view.documentUrl } })
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showBlacklistWarning,
            callback: function ($$v) {
              _vm.showBlacklistWarning = $$v
            },
            expression: "showBlacklistWarning",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [_c("span", [_vm._v("Warning")]), _c("v-spacer")],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "Some of the Information entered above has been flagged down on the Credit Bureau's Blacklist! In accordance to FCRA regulations, our team is required to conduct further research. A Sale representative will contact you as soon as possible with additional information. Thank you! "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showBlacklistWarning = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }