var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-0" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _c("h2", { staticClass: "step-title" }, [
                      _vm._v("Credit Information"),
                    ]),
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pb-2": "" } },
                        [
                          _c("p", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogFCRA = 1
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                Fair Credit Reporting Act\n              "
                                ),
                              ]
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "I understand that the credit report is to be used for tenancy purposes only."
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              on: { change: _vm.radioChanged },
                              model: {
                                value: _vm.form.creditReportUse,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "creditReportUse", $$v)
                                },
                                expression: "form.creditReportUse",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pb-2": "" } },
                        [
                          _vm._v(
                            "\n            Do you understand that your business, as the end user, cannot resell the information?\n            "
                          ),
                          _c(
                            "v-radio-group",
                            {
                              on: { change: _vm.radioChanged },
                              model: {
                                value: _vm.form.agreeNoResell,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "agreeNoResell", $$v)
                                },
                                expression: "form.agreeNoResell",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pb-2": "" } },
                        [
                          _vm._v(
                            "\n            Do you want the applicant to get a copy of the decision letter?\n            "
                          ),
                          _c(
                            "v-radio-group",
                            {
                              on: { change: _vm.radioChanged },
                              model: {
                                value: _vm.form.getDecisionLetter,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "getDecisionLetter", $$v)
                                },
                                expression: "form.getDecisionLetter",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "400" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v("\n        Alert\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showAlert = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                !_vm.form.creditReportUse
                  ? _c("span", [
                      _c("p", [
                        _vm._v(
                          "Sorry, as per FCRA we are not allowed to provide credit reports for purposes other than tenancy."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.form.agreeNoResell
                  ? _c("span", [
                      _c("p", [
                        _vm._v(
                          "Sorry, as per PCI compliance we are not allowed to provide credit reports to resellers."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.form.getDecisionLetter
                  ? _c("span", [
                      _c("p", [
                        _vm._v(
                          "Applicants have  a legal right to receive a copy of the decision letter, please select ' yes'"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n        If you have any questions feel free to contact us at\n        "
                ),
                _c(
                  "a",
                  {
                    staticClass: "white--text",
                    attrs: { href: "mailto:compliance@tenantevaluation.com" },
                  },
                  [_vm._v("compliance@tenantevaluation.com")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "800" },
          model: {
            value: _vm.dialogFCRA,
            callback: function ($$v) {
              _vm.dialogFCRA = $$v
            },
            expression: "dialogFCRA",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        Federal Fair Credit Reporting Act (FCRA)\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFCRA = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "(as amended by the Consumer Credit Reporting Reform Act of 1996)"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Although the FCRA primarily regulates the operations of consumer credit reporting agencies, it also affects you as a user of information. We have included a copy of the FCRA with your membership kit. We suggest that you and your employees become familiar with the following sections in particular:"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "\n          604. Permissible Purposes of Reports\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "607. Compliance Procedures 615. Requirement on users of consumer reports\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "616. Civil liability for willful noncompliance\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "617. Civil liability for negligent noncompliance\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "619. Obtaining information under false pretenses\n          "
                  ),
                  _c("br"),
                  _vm._v("621. Administrative Enforcement\n          "),
                  _c("br"),
                  _vm._v(
                    "623. Responsibilities of Furnishers of Information to Consumer Reporting Agencies\n          "
                  ),
                  _c("br"),
                  _vm._v("628. Disposal of Records\n        "),
                ]),
                _c("p", [
                  _vm._v(
                    "Each of these sections is of direct consequence to users who obtain reports on consumers. !"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "As directed by the law, credit reports may be issued only if they are to be used for extending credit, review or collection of an account, employment purposes, underwriting insurance or in connection with some other legitimate business transaction such as in investment, partnership, etc. It is imperative that you identify each request for a report to be used for employment purposes when such report is ordered. Additional state laws may also impact your usage of reports for employment purposes.!"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "We strongly endorse the letter and spirit of the Federal Fair Credit Reporting Act. We believe that this law and similar state laws recognize and preserve the delicate balance between the rights of the consumer and the legitimate needs of commerce.!"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "In addition to the Federal Fair Credit Reporting Act, other federal and state laws addressing such topics as computer crime and unauthorized access to protected databases have also been enacted. As a prospective user of consumer reports, we expect that you and your staff will comply with all relevant federal statutes and the statutes and regulations of the states in which you operate."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "We support consumer reporting legislation that will assure fair and equitable treatment for all consumers and users of credit information."
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFCRA = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }