import axios from "axios";
import checksum from "./checksum";

export default {

  async getSunbizDocs(einNumber, propertyId, annualReportDocs, incorporationDocs, $http) {
    let geetsiApiUrl = "https://clientev.geetsi.es/api"

    if (!einNumber) {
      return 'Wrong EIN number'
    }

    let url = geetsiApiUrl + "/ein?query=" + String(einNumber).replace(/\D/g, "");

    const res = await axios.get(url, {
      timeout: 10000,
    });

    if (
      !res.data ||
      !res.data.documents ||
      !res.data.documents.length) {
      if (res.data.status != "ACTIVE") {
        return 'Company is NOT active'
      }
      else {
        return 'Documents for this company NOT found'
      }
    }

    if (!annualReportDocs || !annualReportDocs.length) {
      if (res.data.documents.length > 1) {
        let doc = res.data.documents[0];
        const anualReport = await this.getSunbizDoc(geetsiApiUrl, doc.url);
        if (anualReport){
          await this.uploadDoc(
            {
              data: anualReport.data,
              name: doc.name,
              type: "application/pdf"
            },
            "annual_report",
            propertyId,
            $http
          );
        }
      }
    }

    if (!incorporationDocs || !incorporationDocs.length) {
      let doc = res.data.documents[res.data.documents.length - 1];
      const incorporation = await this.getSunbizDoc(geetsiApiUrl, doc.url);
      if (incorporation){
        await this.uploadDoc(
          {
            data: incorporation.data,
            name: doc.name,
            type: "application/pdf"
          },
          "incorporation",
          propertyId,
          $http
        );
      }
    }

    return null
  },

  async getSunbizDoc(geetsiApiUrl, url) {
    let geturl = geetsiApiUrl +
      "/download?url=" +
      encodeURIComponent(url) +
      "&ch=" +
      checksum(url)

    //geturl = 'https://httpstat.us/200?sleep=20000'

    const res = await axios.get(geturl, {
      timeout: 15000
    })
    .catch(error => {
      console.error('error.code ' + error.code)
    });

    return (res)?res.data:null;
  },

  async uploadDoc(file, description, propertyId, $http) {

    await $http
      .post("/manager/uploadDocument", {
        propertyId: propertyId,
        description: description,
        filename: file["name"],
        fileMimeType: file["type"],
        fileData: file["data"]
      })
      .then(() => {

      })
      .catch(e => {
        // eslint-disable-next-line
        console.error(e);
      })
      .finally(() => {

      });
  },

  async removeDoc(id, $http) {
    await $http
      .delete("/manager/documents/" + id)
      .then(() => {

      })
      .catch(e => {
        console.error(e);
      });
  },

  removeDocPromise(id, $http) {
    return new Promise((resolve, reject) => {
    $http.delete("/manager/documents/" + id)
        .then(() => { resolve(); })
        .catch(e => {
          console.error(e);
          reject(e);
        });
  })},

}
