<template>
  <div>
    <v-dialog v-model="showDialog" :fullscreen="true">
      <v-card class="dialogViewer">
        <v-toolbar fixed>
          <v-toolbar-title>File Preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <transition name="fade">
          <iframe :src="viewData"></iframe>
        </transition>
      </v-card>
    </v-dialog>

    <v-card-text class="pt-0">
      <v-form ref="form">
        <v-container>
          <div class="mb-4">
            <h2 class="step-title">Documents</h2>
          </div>
          <v-layout row wrap text-xs-center pb-5 v-if="isLoading">
            <v-flex>
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-flex>
          </v-layout>

          <v-layout row wrap v-if="!isLoading">
            <v-flex xs12 mb-3 v-if="extraDocumentLimit">
              <h3>Basic document collection</h3>
            </v-flex>

            <v-flex xs12 mb-3 v-if="!extraDocumentLimit">
              <h3 style="color:#2196F3;">Document collection</h3>
              <h4>Tenant Evaluton will collect the following documents for all type of applicants:</h4>
            </v-flex>

            <v-flex xs12 mb-3>
              <v-layout d-flex align-center justify-space-between px-3 py-3 class="layout-included">
                <span class="doc-title"><strong>Photo Identification</strong> (US Driver's License or Passport)*</span>
                <span class="doc-required text-muted">required</span>
              </v-layout>
            </v-flex>

            <v-flex xs12 mb-3>
              <v-layout d-flex align-center justify-space-between px-3 py-3 class="layout-included">
                <span class="doc-title"><strong>Marriage Certificate</strong> (If applicable and if married with different last names)*</span>
                <span class="doc-required text-muted">required</span>
              </v-layout>
            </v-flex>

            <v-flex xs12 mb-3>
              <v-layout d-flex align-center justify-space-between px-3 py-3 class="layout-included">
                <span class="doc-title"><strong>Pet Photo</strong> (If applicable)</span>
                <span class="doc-required text-muted">required</span>
              </v-layout>
            </v-flex>

            <v-flex xs12 mb-3 v-if="currentApp.qaLetter">
              <v-layout d-flex align-center justify-space-between px-3 py-3 class="layout-included">
                <span class="doc-title"><strong>Quick-approve letter customization</strong><br/>
                <span class="qa-text"><p>You have selected that you would like a customized approval letter to match your own branding, please send a copy of the custom approval letter to compliance@tenantevaluation.com</p>
                </span>
                </span>
                <UploadButton
                  :color="form.qaLetterDocUrl?'black':'primary'"
                  labelClass="white--text"
                  large
                  depressed
                  v-bind:title="form.qaLetterDocUrl?form.qaLetterDocUrl.split(/(\\|\/)/g).pop().split(/\?/g).shift():'Upload Now'"
                  v-bind:multiple="false"
                  v-bind:accept="'.doc,.docx,.pdf'"
                  v-bind:done="uploadQaLetterDoc"
                  v-if="false"
                ></UploadButton>
                <v-btn
                    large
                    depressed
                    color="primary"
                    :loading="true"
                    v-bind:class="{'blue--text': true}"
                    :disabled="true"
                    v-if="isUploading.qaLetter"
                ></v-btn>
                <v-btn v-if="false" flat icon @click="viewDoc('qaLetter')" color="grey">
                  <v-icon class="rotate">visibility</v-icon>
                </v-btn>
                <v-btn v-if="false" flat icon @click="removeDoc('qaLetter')" color="grey">
                  <v-icon class="rotate">delete</v-icon>
                </v-btn>
              </v-layout>
            </v-flex>

            <v-flex xs12 mb-3 v-if="currentApp.deluxeFee">
              <v-layout d-flex align-center justify-space-between px-3 py-3 class="layout-included">
                <span class="doc-title"><strong>Deluxe Application</strong><br/>
                <span class="qa-text"><p>You have selected that you would like to customize your application process with your own rules and regulations, and community forms on the proposal, please send the documents to compliance@tenantevaluation.com</p>
                </span>
                </span>
                <UploadButton
                  :color="form.deluxeFeeDocUrl?'black':'primary'"
                  labelClass="white--text"
                  large
                  depressed
                  v-bind:title="form.deluxeFeeDocUrl?form.deluxeFeeDocUrl.split(/(\\|\/)/g).pop().split(/\?/g).shift():'Upload Now'"
                  v-bind:multiple="false"
                  v-bind:accept="'.doc,.docx,.pdf'"
                  v-bind:loading="true"
                  v-bind:done="uploadDeluxeFeeDoc"
                  v-if="false"
                ></UploadButton>
                <v-btn
                    large
                    depressed
                    color="primary"
                    :loading="true"
                    v-bind:class="{'blue--text': true}"
                    :disabled="true"
                    v-if="false"
                ></v-btn>
                <v-btn v-if="false" flat icon @click="viewDoc('deluxeFee')" color="grey">
                  <v-icon class="rotate">visibility</v-icon>
                </v-btn>
                <v-btn v-if="false" flat icon @click="removeDoc('deluxeFee')" color="grey">
                  <v-icon class="rotate">delete</v-icon>
                </v-btn>
              </v-layout>
            </v-flex>

            <v-flex
              xs12
              pb-2
              style="font-size:14px;"
            >* Please note Tenant Evaluation will collect the Photo ID as a mandatory requirement to confirm the identity and Marriage Certificate (If applicable and if married with different last names) to verify application fees.</v-flex>

            <v-flex xs12 mt-2 mb-3 v-if="extraDocumentLimit">
              <h3 style="color:#2196F3;">You can collect up to {{extraDocumentLimit}} extra documents</h3>
              <h4 class="mb-3">Select additional documents</h4>
              <span class="text-muted">Please list the documents you want Tenant Evaluation to collect from the applicant on your behalf.</span>

              <div v-for="(item, index) in selectedDocs" v-bind:key="index" >
                <v-flex xs12>
                  <v-layout flex blue--text align-center justify-space-between>
                    <span>Document No.{{index+1}}</span>
                    <v-btn flat icon @click="removeDocument(index)" color="grey">
                      <v-icon class="rotate">delete</v-icon>
                    </v-btn>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs6 class="pr-3">
                      <template v-if="selectedDocs[index].standardDocumentId != -1">
                        <v-select
                          :autofocus="true"
                          :menu-props="{contentClass:'select-doctype'}"
                          v-model="selectedDocs[index].standardDocumentId"
                          v-on:change="docChange(index)"
                          item-value="id"
                          item-text="description"
                          :items="selectDocuments"
                          placeholder="Select type of document"
                          single-line
                          box
                        ></v-select>
                      </template>
                      <template v-else>
                        <v-text-field
                          ref="field"
                          browser-autocomplete="off"
                          v-model="selectedDocs[index].description"
                          :autofocus="true"
                          label="Document Name"
                          placeholder="Please type the document name"
                          required
                          box
                        ></v-text-field>
                      </template>
                    </v-flex>
                    <v-flex xs6 class="pl-3">
                      <v-select
                        v-model="selectedDocs[index].designation"
                        v-on:change="designationChange"
                        :items="designationTypes[index]"
                        :autofocus="false"
                        label="Applies To"
                        box
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </div>
              <v-flex xs12 v-if="extraDocumentLimit && differentDocuments<extraDocumentLimit">
                <div v-ripple class="d-inline-block pr-3 rounded" @click="addNewDocument">
                  <v-btn color="primary" fab small dark depressed>
                    <v-icon>add</v-icon>
                  </v-btn>
                  <a class="ml-1">Add document</a>
                </div>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-dialog scrollable v-model="showAlert" width="600">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          Alert
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showAlert = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <span>
            The Income report directly connects to an applicants' bank account, providing a real-time reflection of the applicants' financial standing. The report includes a compilation of all deposit history, current balance history and assigns a confidence score for ranking. The security of applicants' data is our top priority. As such, only information necessary for approval is displayed on the Income Report.
            <br/><br/>
            For:
            <br/>
            <ul>
              <li>International Applicants</li>
              <li>Applicants without an American Bank Account</li>
              <li>Applicants who deny authorization access to their bank accounts</li>
            </ul>
            <br/>
            Tenant Evaluation will alternatively collect paperwork that demonstrates an equivalent representation of the applicant's financial standing. This will include Paystubs, 1099 forms, Bank Statements, etc.
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import config from '@/config';

import Validator from "../../libs/validator";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";
import UploadButton from "../../components/UploadButton";
import axios from "../../backend/vue-axios/axios";

export default {
  mixins: [DisableAutocomplete],
  name: "DocumentsStep",
  props: {
    step: Number
  },
  data() {
    const validator = new Validator();
    return {
      showDialog: false,
      viewData: null,
      isLoading: false,
      showAlert: false,
      designationTypes: [],
      isUploading: {
        deluxe: false,
        qaLetter: false
      },
      form: {
        docs: [],
        qaLetterDocUrl: null,
        qaLetterDoc: null,
        deluxeFeeDocUrl: null,
        deluxeFeeDoc: null,
      },
      originalStandardDocuments: [],
      standardDocuments: [],
      selectedDocs: [],
      rules: {
        required: [validator.rules.required()]
      }
    };
  },
  components: {
    UploadButton
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    }),
    extraDocumentLimit() {
      return this.currentApp["extraDocumentLimitTenant"] || this.currentApp["extraDocumentLimitGuest"] || this.currentApp["extraDocumentLimitPurchase"]
    },
    selectDocuments() {
      const select = [];
      this.standardDocuments.forEach(item => {
        if (!item.included) {
          select.push(item);
        }
      });
      /*select.push({
        standardDocumentId: -1,
        description: "Add Custom Document"
      });*/
      return select;
    },
    differentDocuments() {
      // sets are, well, sets of unique items. no duplicates allowed
      let uniqueId = new Set();
      for(let seldoc of this.selectedDocs){
          uniqueId.add(seldoc.standardDocumentId);
      }
      // the size of the set is the number of unique items we added
      return uniqueId.size;
    },
    proofOfIncome() {
      return this.currentApp["proofIncomeLease"] || this.currentApp["proofIncomePurchase"] || this.currentApp["proofIncomeGuest"]
    },
    proofOfIncomeTypes() {
      let text = "";
      if (this.currentApp["proofIncomeLease"]) text = "Lease /";
      if (this.currentApp["proofIncomePurchase"]) text += "Purchase /";
      if (this.currentApp["proofIncomeGuest"]) text += "Guest /";
      text = text.substring(0, text.length-2)
      return text
    },

  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    submitForm(autosaveMode=false) {
      const _this = this;
      if (!this.$refs.form.validate()) {
        return this.alertError("Validation error")
      }

      if (this.extraDocumentLimit && this.differentDocuments>this.extraDocumentLimit) {
        return this.alertError("Please note only " + this.extraDocumentLimit + " different documents are allowed.")
      }

      if(!autosaveMode && this.extraDocumentLimit && this.selectedDocs.length==0) {
        return this.alertError("Please create at least one additional document.")
      }

      // if (this.currentApp.qaLetter && (!this.form.qaLetterDocUrl && !this.form.qaLetterDoc)){
      //   return this.alertError("The upload selected above is mandatory. Please provide a copy of the Custom Quick Approve Letter to proceed.")
      // }
      //
      // if (this.currentApp.deluxeFee && (!this.form.deluxeFeeDocUrl && !this.form.deluxeFeeDoc)){
      //   return this.alertError("The upload selected above is mandatory. Please provide a copy of your Property Forms ( ie Rules and regulations, Addendums etc).")
      // }

      const docPhotoID = this.originalStandardDocuments.find(element => element.description.toUpperCase().includes("PHOTO ID"))
      const docMarriageCert = this.originalStandardDocuments.find(element => element.description.toUpperCase().includes("MARRIAGE CERT"))
      this.form.docs = [];
      this.form.docs.push(docPhotoID)
      this.form.docs.push(docMarriageCert)

      const petDesignation = this.getPetsDesignation()
      if (petDesignation !== "") {
        let docPetPhoto = this.originalStandardDocuments.find(element => element.description.toUpperCase().includes("PET PHOTO"))
        docPetPhoto.designation = petDesignation
        this.form.docs.push(docPetPhoto)
      }

      let errorMissing = false
      let countByType = {
        All: 0,
        Lease: 0,
        Purchase: 0,
        Guest: 0,
      }

      for (let index in this.selectedDocs) {
        let item = this.selectedDocs[index]
        if ((!item.standardDocumentId && !item.description) || !item.designation) {
          errorMissing = true
          break
        }
        else if (item.standardDocumentId && item.standardDocumentId > 0) {
          let found = _this.standardDocuments.find(el => {
            return el.id == item.standardDocumentId
          });
          if (!found) continue
          item.description = found.description
          item.code = found.code
        } else if (item.description) {
          delete item.standardDocumentId
        }

        if (item.designation=="All") {
          countByType.All++
        }
        else if (item.designation=="Lease/Guest") {
          countByType.Lease++
          countByType.Guest++
        }
        else if (item.designation=="Lease/Purchase") {
          countByType.Lease++
          countByType.Purchase++
        }
        else if (item.designation=="Purchase/Guest") {
          countByType.Purchase++
          countByType.Guest++
        }
        else if (item.designation=="Lease") countByType.Lease++
        else if (item.designation=="Purchase") countByType.Purchase++
        else if (item.designation=="Guest") countByType.Guest++

        _this.form.docs.push(item)
      }
      if (errorMissing) {
        if (autosaveMode) return
        else return this.alertError("Please select the missing document type.")
      }


      const nDocsLeases = (countByType.All - 2) + countByType.Lease
      const nDocsPurchase = (countByType.All - 2) + countByType.Purchase
      const nDocsGuest = (countByType.All - 2) + countByType.Guest

      if (this.currentApp.allowLeases && nDocsLeases > this.extraDocumentLimit) {
          return this.alertError("The number of documents requested for Lease applications is bigger than allowed")
      }
      if (this.currentApp.allowPurchases && nDocsPurchase > this.extraDocumentLimit) {
          return this.alertError("The number of documents requested for Purchase applications is bigger than allowed")
      }
      if (this.currentApp.allowGuests && nDocsGuest > this.extraDocumentLimit) {
          return this.alertError("The number of documents requested for Guest applications is bigger than allowed")
      }

      this.currentApp.stepUsers = false
      this.currentApp.stepDocuments = true
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)
      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
          if (!autosaveMode) {
            _this.$events.$emit("success", {
              step: _this.step
            });
          } else {
            _this.convertDesignations();
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => {
          _this.convertDesignations()
          _this.form.qaLetterDoc = null
          _this.form.deluxeFeeDoc = null
        });
    },
    addNewDocument() {
      let errorMissing = false
      for (let index in this.selectedDocs) {
        let item = this.selectedDocs[index]
        if (!item.standardDocumentId && !item.description) {
          errorMissing = true
          break
        }
      }
      if (errorMissing) {
        return this.alertError("Please select the missing document type, before adding more documents.")
      }

      this.selectedDocs.push({
        standardDocumentId: null,
        description: null,
        designation: null,
        code: null
      });
      this.designationTypes.push([])
    },
    updateDesignationType(index) {
      if (!index) index = this.selectedDocs.length-1
      let stdDoc = null
      if (this.selectedDocs[index].standardDocumentId) stdDoc = this.standardDocuments.find(element => element.id == this.selectedDocs[index].standardDocumentId)
      else stdDoc = this.standardDocuments.find(element => element.description == this.selectedDocs[index].description)
      this.designationTypes[index] = stdDoc.designationTypes
    },
    removeDocument(index) {
      if (this.selectedDocs[index].id) {
        const _this = this;
        this.$http
          .delete("/manager/delete/task/" + this.selectedDocs[index].id)
          .then(() => {
            _this.selectedDocs.splice(index, 1)
            _this.designationTypes.splice(index, 1)
          })
          .catch(e => {
            // eslint-disable-next-line
            console.log(e)
            _this.selectedDocs.splice(index, 1)
            _this.designationTypes.splice(index, 1)
          })
      } else {
        this.selectedDocs.splice(index, 1);
        this.designationTypes.splice(index, 1);
      }
    },
    prepareDocs() {
      // Remove fixed documents (photo id and marriage certificate)
      this.standardDocuments = this.originalStandardDocuments.filter(element => (
        !element.description.toUpperCase().includes("PHOTO ID") &&
        !element.description.toUpperCase().includes("MARRIAGE CERT") &&
        !element.description.toUpperCase().includes("PET PHOTO")))

      // Prepare available types and filter docs not needed
      let petsAllowed = false
      if (this.currentApp.allowLeases) {
        if (this.currentApp.leaseAllowPets) petsAllowed = true
      } else {
        this.standardDocuments = this.standardDocuments.filter(element => (
          element.designation !== "LEASE"))
      }
      if (this.currentApp.allowPurchases) {
        if (this.currentApp.purchaseAllowPets) petsAllowed = true
      } else {
        this.standardDocuments = this.standardDocuments.filter(element => (
          element.designation !== "PURCHASE"))
      }
      if (this.currentApp.allowGuests) {
        if (this.currentApp.guestAllowPets) petsAllowed = true
      } else {
        this.standardDocuments = this.standardDocuments.filter(element => (
          element.designation !== "GUEST"))
      }
      // Remove docs if pets NOT allowed
      if (!petsAllowed) {
        this.standardDocuments = this.standardDocuments.filter(element => (element.taskFilter !== "Pet"))
      }

      for (let index in this.standardDocuments) {
        this.standardDocuments[index].designationTypes = []
        let includeLease = this.currentApp.allowLeases && (this.standardDocuments[index].designation.toUpperCase().includes("LEASE") || this.standardDocuments[index].designation=="All")
        let includePurchase = this.currentApp.allowPurchases && (this.standardDocuments[index].designation.toUpperCase().includes("PURCHASE") || this.standardDocuments[index].designation=="All")
        let includeGuest = this.currentApp.allowGuests && (this.standardDocuments[index].designation.toUpperCase().includes("GUEST")|| this.standardDocuments[index].designation=="All")
        if (this.standardDocuments[index].taskFilter == "Pet") {
          if (!this.currentApp.leaseAllowPets) includeLease = false
          if (!this.currentApp.purchaseAllowPets) includePurchase = false
          if (!this.currentApp.guestAllowPets) includeGuest = false
        }

        if (includeLease) this.standardDocuments[index].designationTypes.push({text:"Lease",value:"Lease"})
        if (includePurchase) this.standardDocuments[index].designationTypes.push({text:"Purchase",value:"Purchase"})
        if (includeGuest) this.standardDocuments[index].designationTypes.push({text:"Guest",value:"Guest"})

        // If many types then add All
        if (this.standardDocuments[index].designationTypes.length == 3) {
          this.standardDocuments[index].designationTypes.unshift({text:"All",value:"All"})
        }

        if (includeLease && includePurchase) this.standardDocuments[index].designationTypes.push({text:"Lease/Purchase",value:"LEASE_PURCHASE"})
        if (includeLease && includeGuest) this.standardDocuments[index].designationTypes.push({text:"Lease/Guest",value:"LEASE_GUEST"})
        if (includePurchase && includeGuest) this.standardDocuments[index].designationTypes.push({text:"Purchase/Guest",value:"PURCHASE_GUEST"})
      }
    },
    loadStandardDocs() {
      const _this = this;
      this.isLoading = true;
      this.$http
        .get("/manager/standard-documents")
        .then(response => {
          _this.standardDocuments = response.data;
          _this.originalStandardDocuments = JSON.parse(JSON.stringify(_this.standardDocuments))
          //console.log("standard-documents", response.data)
          _this.prepareDocs();

          // Set documents
          _this.form.docs.forEach(item => {
              if (!item.description.toUpperCase().includes("PHOTO ID") &&
                !item.description.toUpperCase().includes("MARRIAGE CERT") &&
                !item.description.toUpperCase().includes("PET PHOTO") ){

                const stdDoc = _this.standardDocuments.find(element => element.description == item.description)
                if (stdDoc) {
                  item.standardDocumentId = stdDoc.id
                  _this.selectedDocs.push(item);
                  _this.designationTypes.push([])
                  _this.updateDesignationType()
                }
            }
          });
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => {
          _this.isLoading = false;
        });
    },
    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null && this.currentApp[key] !== 0) {
          this.form[key] = this.currentApp[key];
        }
      }
    },
    docChange(index) {
      console.log("docChange ", this.selectedDocs[index])
      console.log("this.standardDocuments ", this.standardDocuments)
      const id = this.selectedDocs[index].standardDocumentId
      const stdDoc = this.standardDocuments.find(element => element.id == id)
      const upperDesc = stdDoc.description.toUpperCase()
      // if (upperDesc.includes("INCOME") || upperDesc.includes("BANK STATEMENT")) {
      //   this.showAlert = true
      // }
      // calculate desingation types
      this.updateDesignationType(index)

      //this.selectedDocs[index].designation = this.designationTypes[index][0]
    },
    designationChange() {
      this.submitForm(true)
    },
    async uploadQaLetterDoc(file) {
      this.isUploading['qaLetter'] = true;
      const itemData = {
        propertyId: this.currentApp.propertyId,
        description: "QaLetter",
        filename: file["name"],
        fileMimeType: file["type"],
        fileData: file["data"]
      };
      if (file.size.replace(' kB','') > 1024 * 20) {
        this.alertError('File too big (> 20MB)');
        this.isUploading['qaLetter'] = false;
        return;
      }
      axios.post(config.restUrl + 'manager/uploadDeluxeFeeDocument', itemData).then(() => {
          this.form.qaLetterDoc = file.data;
          this.form.qaLetterDocUrl = file.name;
      }).catch(e => {
        console.error(e);
        this.alertError('Document could not be imported');
      });
      this.isUploading['qaLetter'] = false;
    },
    async uploadDeluxeFeeDoc(file) {
      this.isUploading['deluxe'] = true;
      const itemData = {
          propertyId: this.currentApp.propertyId,
          description: "deluxeFee",
          filename: file["name"],
          fileMimeType: file["type"],
          fileData: file["data"]
      };
      if (file.size.replace(' kB','') > 1024 * 50) {
        this.alertError('File too big (> 50MB)');
        this.isUploading['deluxe'] = false;
        return;
      }
      axios.post(config.restUrl + 'manager/uploadDeluxeFeeDocument', itemData).then(() => {
          this.form.deluxeFeeDoc = file.data;
          this.form.deluxeFeeDocUrl = file.name;
      }).catch(e => {
        console.error(e);
        this.alertError('Document could not be imported');
      });
      this.isUploading['deluxe'] = false;
    },
    getPetsDesignation() {
      let lease = false;
      let purchase = false;
      let guest = false;
      if (this.currentApp.allowLeases && this.currentApp.leaseAllowPets)  lease = true;
      if (this.currentApp.allowPurchases && this.currentApp.purchaseAllowPets)  purchase = true;
      if (this.currentApp.allowGuests && this.currentApp.guestAllowPets)  guest = true;
      if (lease && purchase && guest) return "All"
      else if (lease && purchase) return "LEASE_PURCHASE"
      else if (lease && guest) return "LEASE_GUEST"
      else if (purchase && guest) return "PURCHASE_GUEST"
      else if (lease) return "Lease"
      else if (purchase) return "Purchase"
      else if (guest) return "Guest"
      return ""
    },
    viewDoc(documentType) {
      if(documentType == 'qaLetter') {
        if(this.form.qaLetterDoc) {
          this.viewData = 'data:application/pdf;base64,'+this.form.qaLetterDoc;
        } else {
          this.viewData = this.currentApp.qaLetterDocUrl;
        }
      } else if (documentType == 'deluxeFee') {
        if(this.form.deluxeFeeDoc) {
          this.viewData = 'data:application/pdf;base64,'+this.form.deluxeFeeDoc;
        } else {
          this.viewData = this.currentApp.deluxeFeeDocUrl;
        }
      }
      this.showDialog = true;
    },
    removeDoc(documentType) {
      let endpoint = '';
      if (documentType === 'qaLetter') {
        this.form.qaLetterDoc = null;
        this.form.qaLetterDocUrl = null;
        endpoint = 'removeQaLetterDocument';
      } else if (documentType === 'deluxeFee') {
        this.form.deluxeFeeDoc = null;
        this.form.deluxeFeeDocUrl = null;
        endpoint = 'removeDeluxeFeeDocument';
      }
      const itemData = {
        propertyId: this.currentApp.propertyId,
        description: documentType,
        filename: null,
        fileMimeType: null,
        fileData: null
      };
      axios.post(config.restUrl + `manager/${endpoint}`, itemData).then(() => {
        if (documentType === 'qaLetter') {
          this.form.qaLetterDoc = null;
          this.form.qaLetterDocUrl = null;
        } else if (documentType === 'deluxeFee') {
          this.form.deluxeFeeDoc = null;
          this.form.deluxeFeeDocUrl = null;
        }
      }).catch(e => {
        console.error(e);
        this.alertError('Document could not be deleted');
      });
    },
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
      else if (e.step == this.step - 1) {
        // Refresh pet docs
        this.prepareDocs();
      }
    }
  },
  mounted() {
    this.fillForm();
    this.loadStandardDocs();
  }
};
</script>
<style>
.v-toolbar--fixed {
  z-index: 3;
}

.layout-included {
  background-color: #fff;
  border-radius: 4px;
}

.layout-included .doc-title {
  width: 50%;
  text-align: left;
  font-size: 14px;
}

.layout-included .doc-required{
  width: 20%;
  text-align: right;
  font-size: 14px;
}


.layout-included > div {
  width: 30%;
  text-align: right;
}

.layout-included > div > svg {
  margin-left: auto;
  margin-right: 0px !important;
}

.text-muted {
  color: #696969;
}

.qa-text {
  color: rgba(0,0,0,.54);
}

</style>
