var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { staticClass: "pt-0" },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-container",
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("h2", { staticClass: "step-title" }, [
                  _vm._v("Community Funding"),
                ]),
              ]),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  !_vm.form.disablePricingInformation
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "browser-autocomplete": "off",
                              label: "Accounts payable contact name",
                              rules: _vm.rules.required,
                              required: "",
                              box: "",
                            },
                            on: { blur: _vm.autosave },
                            model: {
                              value: _vm.form.accountsPayableName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "accountsPayableName", $$v)
                              },
                              expression: "form.accountsPayableName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.form.disablePricingInformation
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "browser-autocomplete": "off",
                              label: "Accounts payable contact email",
                              rules: _vm.rules.email,
                              required: "",
                              box: "",
                            },
                            on: { blur: _vm.autosave },
                            model: {
                              value: _vm.form.accountsPayableEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "accountsPayableEmail", $$v)
                              },
                              expression: "form.accountsPayableEmail",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    "ACH is an electronic funds transfer system that moves money between tenants and your community's bank accounts. Most of our clients use ACH and find the process faster and easier. This option is free of charge. A copy of a voided check is necessary."
                  ),
                ]),
              ]),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("strong", [
                      _vm._v(
                        "Define how the percentage values should apply for each of the following"
                      ),
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v("Split%: (eg. Management 50% - Community 50%)"),
                    ]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "mb-3": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Management %",
                          rules: [_vm.checkSplitRange],
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.splitManagement,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "splitManagement", $$v)
                          },
                          expression: "form.splitManagement",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "mb-3": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Community %",
                          readonly: "",
                          required: "",
                          box: "",
                        },
                        model: {
                          value: _vm.form.splitCommunity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "splitCommunity", $$v)
                          },
                          expression: "form.splitCommunity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mb-3": "" } },
                    [
                      _c("strong", [
                        _vm._v("How do you want us to fund your account?"),
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.form.fundAccount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fundAccount", $$v)
                            },
                            expression: "form.fundAccount",
                          },
                        },
                        _vm._l(_vm.fundAccountOptions, function (item, index) {
                          return _c("v-radio", {
                            key: index,
                            attrs: { color: "blue", label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.fundAccount === "Already on file"
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                color: "primary",
                                label: "I agree and authorize",
                                error: !_vm.form.agreeACHTerms,
                              },
                              model: {
                                value: _vm.form.agreeACHTerms,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "agreeACHTerms", $$v)
                                },
                                expression: "form.agreeACHTerms",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.form.fundAccount === "Check"
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "grey--text": "", "mb-3": "" } },
                          [
                            _vm._v(
                              "An extra $10.00 fee will be charged each month in the event that Rebate Checks are requested."
                            ),
                          ]
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm._v(
                              "\n            This authority is to remain in full force and effect until Tenant Evaluation has received written notification from me of its termination in such time and in such manner as to afford Tenant Evaluation a reasonable opportunity to act on it.\n            "
                            ),
                            _c("v-checkbox", {
                              attrs: {
                                color: "primary",
                                label: "I agree",
                                error: !_vm.form.agreeACHTerms,
                              },
                              model: {
                                value: _vm.form.agreeACHTerms,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "agreeACHTerms", $$v)
                                },
                                expression: "form.agreeACHTerms",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.form.fundAccount === "ACH"
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: ["Checking", "Savings"],
                                rules: _vm.rules.required,
                                label: "Account Type",
                                box: "",
                              },
                              model: {
                                value: _vm.form.achAccountType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achAccountType", $$v)
                                },
                                expression: "form.achAccountType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-flex", { attrs: { xs12: "", "mb-4": "" } }, [
                          _vm._v("\n            I\n            "),
                          _vm.propertyName
                            ? _c("strong", { staticClass: "green--text" }, [
                                _vm._v(_vm._s(_vm.propertyName)),
                              ])
                            : _vm._e(),
                          _vm._v(
                            " hereby authorize Tenant Evaluation LLC, hereinafter called Tenant Evaluation to initiate debit entries or such adjusting entries, either credit or debit which are necessary for corrections, to my\n            "
                          ),
                          _c("span", { staticClass: "green--text" }, [
                            _vm.form.achAccountType == null
                              ? _c("strong", [_vm._v("____")])
                              : _vm._e(),
                            _vm.form.achAccountType
                              ? _c("strong", [
                                  _vm._v(_vm._s(_vm.form.achAccountType)),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(
                            " account indicated below and the financial institution named below to debit (or credit) the same to such account.\n          "
                          ),
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Financial Institution Name",
                                rules: _vm.rules.required,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.achInstitutionName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achInstitutionName", $$v)
                                },
                                expression: "form.achInstitutionName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "City",
                                rules: _vm.rules.required,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.achCity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achCity", $$v)
                                },
                                expression: "form.achCity",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                "browser-autocomplete": "off",
                                items: _vm.statesJson,
                                "item-text": "name",
                                "item-value": "abbr",
                                label: "State",
                                rules: _vm.rules.required,
                                box: "",
                              },
                              model: {
                                value: _vm.form.achState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achState", $$v)
                                },
                                expression: "form.achState",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Zip Code",
                                rules: _vm.rules.zipcode,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.achZip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achZip", $$v)
                                },
                                expression: "form.achZip",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Transit / Routing number",
                                rules: _vm.rules.routingNumber,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.achRoutingNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achRoutingNumber", $$v)
                                },
                                expression: "form.achRoutingNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "browser-autocomplete": "off",
                                label: "Account number",
                                rules: _vm.rules.accountNumber,
                                required: "",
                                box: "",
                              },
                              model: {
                                value: _vm.form.achAccountNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "achAccountNumber", $$v)
                                },
                                expression: "form.achAccountNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.isLoading
                              ? _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "text-xs-center": "",
                                      "pb-5": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            size: 50,
                                            color: "primary",
                                            indeterminate: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.isLoading
                              ? _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "", "mb-5": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mb-3": "" } },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "Upload a copy of a voided check"
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mb-5": "" } },
                                      [
                                        _vm._v(
                                          "In order to verify the ownership of your account we need to get a copy of a voided check"
                                        ),
                                      ]
                                    ),
                                    _vm.voidedCheckDocs.length
                                      ? _vm._l(
                                          _vm.voidedCheckDocs,
                                          function (item, index) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key: index,
                                                attrs: { xs12: "", "mt-3": "" },
                                              },
                                              [
                                                _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "d-inline mr-3",
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("attachment"),
                                                    ]),
                                                    _vm._v(
                                                      "Voided Check\n                  "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      small: "",
                                                      outline: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.viewDoc(
                                                          item.documentId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("View")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      small: "",
                                                      outline: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeDoc(
                                                          item.documentId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Remove")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      : [
                                          _c(
                                            "v-layout",
                                            {
                                              class: {
                                                "has-advanced-upload2":
                                                  _vm.isAdvancedUpload,
                                              },
                                              attrs: {
                                                "align-center": "",
                                                "justify-center": "",
                                              },
                                            },
                                            [
                                              !_vm.voidedCheckDocs.length &&
                                              !_vm.isUploading
                                                ? _c("UploadButton", {
                                                    attrs: {
                                                      large: "",
                                                      depressed: "",
                                                      title: "Upload",
                                                      multiple: false,
                                                      done: _vm.uploadDoc,
                                                      required: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.isUploading
                                                ? _c("v-btn", {
                                                    class: {
                                                      "blue--text": true,
                                                    },
                                                    attrs: {
                                                      large: "",
                                                      depressed: "",
                                                      color: "primary",
                                                      loading: true,
                                                      disabled: true,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm._v(
                              "\n            This authority is to remain in full force and effect until Tenant Evaluation has received written notification from me of its termination in such time and in such manner as to afford Tenant Evaluation a reasonable opportunity to act on it.\n            "
                            ),
                            _c("v-checkbox", {
                              attrs: {
                                color: "primary",
                                label: "I agree and authorize",
                                error: !_vm.form.agreeACHTerms,
                              },
                              model: {
                                value: _vm.form.agreeACHTerms,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "agreeACHTerms", $$v)
                                },
                                expression: "form.agreeACHTerms",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: true },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogViewer" },
            [
              _c(
                "v-toolbar",
                { attrs: { fixed: "" } },
                [
                  _c("v-toolbar-title", [_vm._v("File Preview")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _vm.view
                ? _c("iframe", { attrs: { src: _vm.view.documentUrl } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }