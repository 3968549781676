var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-0" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _c("h2", { staticClass: "step-title" }, [
                      _vm._v("Compliance"),
                    ]),
                  ]),
                  _c("div", { staticClass: "mb-4" }, [
                    _c(
                      "h2",
                      {
                        staticClass: "property-title",
                        staticStyle: { "padding-left": "0px!important" },
                      },
                      [_vm._v("California Civil Code Section 1785.14(a)")]
                    ),
                    _c("div", { staticClass: "grey--text" }),
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pb-2": "" } },
                        [
                          _c("strong", [
                            _vm._v("Is the end user a retail seller?"),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { autofocus: _vm.$isDesktop() },
                              on: { change: _vm.endUserRetailSellerChanged },
                              model: {
                                value: _vm.form.caComplianceInfo.retailSeller,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.caComplianceInfo,
                                    "retailSeller",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.caComplianceInfo.retailSeller",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  _vm.showDialog = true
                                },
                              },
                            },
                            [_vm._v("California civil code")]
                          ),
                        ]),
                        _vm._v(
                          "In compliance with Section 1785.14(a) of the California Civil Code, Property/Company here by certifies to Consumer Reporting Agency as follows:\n          "
                        ),
                      ]),
                      _c("v-flex", { attrs: { xs12: "", "mb-5": "" } }, [
                        _vm._v(
                          "\n            End User (Property or Management Team)\n            "
                        ),
                        _c(
                          "span",
                          { staticClass: "green--text" },
                          [
                            _vm.form.caComplianceInfo.retailSeller == null
                              ? _c("strong", [_vm._v("____")])
                              : [
                                  _vm.form.caComplianceInfo.retailSeller
                                    ? _c("strong", [_vm._v("IS")])
                                    : _vm._e(),
                                  !_vm.form.caComplianceInfo.retailSeller
                                    ? _c("strong", [_vm._v("IS NOT")])
                                    : _vm._e(),
                                ],
                          ],
                          2
                        ),
                        _vm._v(
                          " a retail seller, as defined in Section 1802.3 of the California Civil Code (“Retail Seller”) and issues credit to consumers who appear in person on the basis of applications for credit submitted in person (“Point of Sale”).\n          "
                        ),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pb-2": "" } },
                        [
                          _c("strong", [
                            _vm._v(
                              "Do you, Qualified Subscriber (Property or Management Team), issue credit to consumers who appear in person on the basis of an application for credit submitted in person?"
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              on: { change: _vm.issuesCreditChanged },
                              model: {
                                value: _vm.form.caComplianceInfo.issuesCredit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.caComplianceInfo,
                                    "issuesCredit",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.caComplianceInfo.issuesCredit",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-3": "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label:
                                "I agree. My signature will be automatically added at the end of this process to reflect my authorization.",
                              error:
                                !_vm.form.caComplianceInfo.agreeCommonSignature,
                            },
                            model: {
                              value:
                                _vm.form.caComplianceInfo.agreeCommonSignature,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.caComplianceInfo,
                                  "agreeCommonSignature",
                                  $$v
                                )
                              },
                              expression:
                                "form.caComplianceInfo.agreeCommonSignature",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mb-4",
                      staticStyle: { "padding-top": "40px" },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "property-title",
                          staticStyle: { "padding-left": "0px!important" },
                        },
                        [
                          _vm._v(
                            "Vermont Fair Credit Reporting Statute 9 V.S.A. § 2480e"
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "grey--text" }),
                    ]
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-3": "" } },
                        [
                          _vm._v(
                            "\n            Do you agree and understand the\n            "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  _vm.showDialogVermont = true
                                },
                              },
                            },
                            [_vm._v("Vermont Credit reporting statute")]
                          ),
                          _vm._v("?\n            "),
                          _c(
                            "v-radio-group",
                            {
                              on: { change: _vm.understandVCRSChanged },
                              model: {
                                value: _vm.form.vtComplianceInfo.understandVCRS,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.vtComplianceInfo,
                                    "understandVCRS",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.vtComplianceInfo.understandVCRS",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-3": "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label:
                                "I agree. My signature will be automatically added at the end of this process to reflect my authorization.",
                              error:
                                !_vm.form.vtComplianceInfo.agreeCommonSignature,
                            },
                            model: {
                              value:
                                _vm.form.vtComplianceInfo.agreeCommonSignature,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.vtComplianceInfo,
                                  "agreeCommonSignature",
                                  $$v
                                )
                              },
                              expression:
                                "form.vtComplianceInfo.agreeCommonSignature",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "400" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v("\n        Alert\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showAlert = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n        Sorry, as per PCI compliance we are not allowed to provide credit reports to resellers. If you have any questions feel free to contact us at\n        "
                ),
                _c(
                  "a",
                  {
                    staticClass: "white--text",
                    attrs: { href: "mailto:compliance@tenantevaluation.com" },
                  },
                  [_vm._v("compliance@tenantevaluation.com")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "400" },
          model: {
            value: _vm.showIssuesCreditAlert,
            callback: function ($$v) {
              _vm.showIssuesCreditAlert = $$v
            },
            expression: "showIssuesCreditAlert",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v("\n        Alert\n        "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showIssuesCreditAlert = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "\n        Sorry, as per FCRA we are not allowed to provide credit reports for purposes other than a tenancy. Sorry. If you have any questions feel free to contact us at\n        "
                ),
                _c(
                  "a",
                  {
                    staticClass: "white--text",
                    attrs: { href: "mailto:compliance@tenantevaluation.com" },
                  },
                  [_vm._v("compliance@tenantevaluation.com")]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "800" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        California Civil Code Section 1785.14(a)\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "Provisions of the California Consumer Credit Reporting Agencies Act, as amended effective July 1, 1998, will impact the provision of consumer reports to Qualified Subscriber under the following circumstances: (a) If Qualified Subscriber is a “retail seller” (defined in part by California law as “a person engaged in the business of selling goods or services to retail buyers”) and is selling to a “retail buyer” (defined as “a person who buys goods or obtains services from a retail seller in a retail installment sale and not principally for the purpose of resale”) and a consumer about whom Qualified Subscriber is inquiring is applying, (b) In person, and (c) for credit. Under the foregoing circumstances, Equifax, before delivering a consumer report to Qualified Subscriber, must match at least three (3) items of a consumer’s identification within the file maintained by Equifax with the information provided to Equifax by Qualified Subscriber in connection with the in-person credit transaction."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Compliance with this law further includes Qualified Subscriber’s inspection of the photo identification of each consumer who applies for in-person credit, mailing extensions of credit to consumers responding to a mail solicitation at specified addresses, taking special actions regarding a consumer’s presentment of a police report regarding fraud, and acknowledging consumer demands for reinvestigations within certain time frames."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "If Qualified Subscriber designated in Paragraph 8 of the Terms and Conditions that it is a “retail seller,” Qualified Subscriber certifies that it will instruct its employees to inspect a photo identification of the consumer at the time an application is submitted in person. If Qualified Subscriber is not currently, but subsequently becomes a “retail seller,” Qualified Subscriber agrees to provide written notice to Equifax prior to ordering credit reports in connection with an in-person credit transaction, and agrees to comply with the requirements of the California law as outlined in this Exhibit, and with the specific certifications set forth herein."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Qualified Subscriber certifies that, as a “retail seller,” it will either (a) acquire a new Qualified Subscriber number for use in processing consumer report inquiries that result from in person credit applications covered by California law, with the understanding that all inquiries using this new Qualified Subscriber number will require that Qualified Subscriber supply at least three items of identifying information from the applicant; or (b) contact Qualified Subscriber’s Equifax sales representative to ensure that Qualified Subscriber’s existing number is properly coded for these transactions."
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "800" },
          model: {
            value: _vm.showDialogVermont,
            callback: function ($$v) {
              _vm.showDialogVermont = $$v
            },
            expression: "showDialogVermont",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "dialogTEV" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        Vermont Fair Credit Reporting Statute 9 V.S.A. § 2480e\n        "
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "white", flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialogVermont = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    "The undersigned, acknowledges that it subscribes to receive various information services from Equifax Information Services LLC (“Equifax”) in accordance with the Vermont Fair Credit Reporting Statute, 9 V.S.A. § 2480e (1999), as amended (the “VFCRA”) and the Federal Fair Credit Reporting Act, 15, U.S.C. 1681 et. Seq., as amended (the “FCRA”) and its other state law counterparts. In connection with Qualified Subscriber’s continued use of Equifax information services in relation to Vermont consumers, Qualified Subscriber hereby certifies as follows:"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Vermont Certification. Qualified Subscriber certifies that it will comply with applicable provisions under Vermont law. In particular, Qualified Subscriber certifies that it will order information services relating to Vermont residents, that are credit reports as defined by the VFCRA, only after Qualified Subscriber has received prior consumer consent in accordance with VFCRA § 2480e and applicable Vermont Rules. Qualified Subscriber further certifies that the attached copy of § 2480e of the Vermont Fair Credit Reporting Statute was received from Equifax."
                  ),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialogVermont = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }