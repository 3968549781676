<template>
  <v-card-text class="pt-0">
    <v-form ref="form">
      <v-container>
        <div class="mb-4">
          <h2 class="step-title">Community Funding</h2>
        </div>
        <v-layout row wrap>
          <v-flex xs12 sm6 v-if="!form.disablePricingInformation">
            <v-text-field
              browser-autocomplete="off"
              v-model="form.accountsPayableName"
              label="Accounts payable contact name"
              :rules="rules.required"
              @blur="autosave"
              required
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 v-if="!form.disablePricingInformation">
            <v-text-field
              browser-autocomplete="off"
              v-model="form.accountsPayableEmail"
              label="Accounts payable contact email"
              @blur="autosave"
              :rules="rules.email"
              required
              box
            ></v-text-field>
          </v-flex>
        </v-layout>

        <div class="mb-4">
          <p>ACH is an electronic funds transfer system that moves money between tenants and your community's bank accounts. Most of our clients use ACH and find the process faster and easier. This option is free of charge. A copy of a voided check is necessary.</p>
        </div>

        <v-layout row wrap>
          <v-flex xs12 mb-3>
            <strong>Define how the percentage values should apply for each of the following</strong><br/>
            <span>Split%: (eg. Management 50% - Community 50%)</span>
          </v-flex>
          <v-flex xs6 mb-3>
            <v-text-field
              v-model="form.splitManagement"
              label="Management %"
              :rules="[checkSplitRange]"
              required
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs6 mb-3>
            <v-text-field
              v-model="form.splitCommunity"
              label="Community %"
              readonly
              required
              box
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 mb-3>
            <strong>How do you want us to fund your account?</strong>
            <v-radio-group v-model="form.fundAccount">
              <v-radio
                color="blue"
                :label="item"
                :value="item"
                :key="index"
                v-for="(item, index) in fundAccountOptions"
              ></v-radio>
            </v-radio-group>
          </v-flex>

          <!-- Already on file -->
          <template v-if="form.fundAccount === 'Already on file'">
            <v-flex xs12>
              <v-checkbox
                color="primary"
                label="I agree and authorize"
                v-model="form.agreeACHTerms"
                :error="!form.agreeACHTerms"
              ></v-checkbox>
            </v-flex>
          </template>

          <!-- Check -->
          <template v-if="form.fundAccount === 'Check'">
            <v-flex
              xs12
              grey--text
              mb-3
            >An extra $10.00 fee will be charged each month in the event that Rebate Checks are requested.</v-flex>
            <v-flex xs12>
              This authority is to remain in full force and effect until Tenant Evaluation has received written notification from me of its termination in such time and in such manner as to afford Tenant Evaluation a reasonable opportunity to act on it.
              <v-checkbox
                color="primary"
                label="I agree"
                v-model="form.agreeACHTerms"
                :error="!form.agreeACHTerms"
              ></v-checkbox>
            </v-flex>
          </template>

          <!-- ACH -->
          <template v-if="form.fundAccount === 'ACH'">
            <v-flex xs12>
              <v-select
                v-model="form.achAccountType"
                :items="['Checking', 'Savings']"
                :rules="rules.required"
                label="Account Type"
                box
              ></v-select>
            </v-flex>
            <v-flex xs12 mb-4>
              I
              <strong class="green--text" v-if="propertyName">{{propertyName}}</strong> hereby authorize Tenant Evaluation LLC, hereinafter called Tenant Evaluation to initiate debit entries or such adjusting entries, either credit or debit which are necessary for corrections, to my
              <span
                class="green--text"
              >
                <strong v-if="form.achAccountType==null">____</strong>
                <strong v-if="form.achAccountType">{{form.achAccountType}}</strong>
              </span> account indicated below and the financial institution named below to debit (or credit) the same to such account.
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.achInstitutionName"
                label="Financial Institution Name"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.achCity"
                label="City"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                browser-autocomplete="off"
                v-model="form.achState"
                :items="statesJson"
                item-text="name"
                item-value="abbr"
                label="State"
                :rules="rules.required"
                box
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.achZip"
                label="Zip Code"
                :rules="rules.zipcode"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.achRoutingNumber"
                label="Transit / Routing number"
                :rules="rules.routingNumber"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.achAccountNumber"
                label="Account number"
                :rules="rules.accountNumber"
                required
                box
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-layout row wrap text-xs-center pb-5 v-if="isLoading">
                <v-flex>
                  <v-progress-circular :size="50" color="primary" indeterminate />
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-5 v-if="!isLoading">
                <v-flex xs12 mb-3>
                  <strong>Upload a copy of a voided check</strong>
                </v-flex>
                <v-flex
                  xs12
                  mb-5
                >In order to verify the ownership of your account we need to get a copy of a voided check</v-flex>
                <template v-if="voidedCheckDocs.length">
                  <v-flex xs12 mt-3 v-for="(item, index) in voidedCheckDocs" v-bind:key="index">
                    <h3 class="d-inline mr-3">
                      <v-icon>attachment</v-icon>Voided Check
                    </h3>
                    <v-btn flat small outline color="primary" @click="viewDoc(item.documentId)">View</v-btn>
                    <v-btn flat small outline color="primary" @click="removeDoc(item.documentId)">Remove</v-btn>
                  </v-flex>
                </template>
                <template v-else>
                  <v-layout
                    align-center
                    justify-center
                    v-bind:class="{'has-advanced-upload2': isAdvancedUpload}"
                  >
                    <UploadButton
                      large
                      depressed
                      v-bind:title="'Upload'"
                      v-bind:multiple="false"
                      v-bind:done="uploadDoc"
                      v-if="!voidedCheckDocs.length&&!isUploading"
                      required
                    ></UploadButton>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      :loading="true"
                      v-bind:class="{'blue--text': true}"
                      :disabled="true"
                      v-if="isUploading"
                    ></v-btn>
                  </v-layout>
                </template>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              This authority is to remain in full force and effect until Tenant Evaluation has received written notification from me of its termination in such time and in such manner as to afford Tenant Evaluation a reasonable opportunity to act on it.
              <v-checkbox
                color="primary"
                label="I agree and authorize"
                v-model="form.agreeACHTerms"
                :error="!form.agreeACHTerms"
              ></v-checkbox>
            </v-flex>
          </template>

        </v-layout>
      </v-container>
    </v-form>

    <v-dialog v-model="showDialog" :fullscreen="true">
      <v-card class="dialogViewer">
        <v-toolbar fixed>
          <v-toolbar-title>File Preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe :src="view.documentUrl" v-if="view"></iframe>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>
<script>
import UploadButton from "../../components/UploadButton";
import Validator from "../../libs/validator";
import StatesJson from "../../states_hash.json";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  name: "CommunityFundingStep",
  props: {
    step: Number
  },
  components: {
    UploadButton
  },
  data() {
    const validator = new Validator();
    return {
      isLoading: false,
      showDialog: false,
      isUploading: false,
      view: null,
      statesJson: StatesJson,
      fundAccountOptions: ["ACH", "Check", "Already on file"],
      form: {
        fundAccount: null,
        achComments: null,
        achPrintedName: null,
        achAccountType: null,
        achInstitutionName: null,
        achCity: null,
        achState: null,
        achZip: null,
        achRoutingNumber: null,
        achAccountNumber: null,
        agreeACHTerms: null,
        accountsPayableName: null,
        accountsPayableEmail: null,
        splitManagement: null,
        splitCommunity: 100.0,
      },
      rules: {
        routingNumber: [validator.rules.routingNumber()],
        accountNumber: [validator.rules.accountNumber()],
        required: [validator.rules.required()],
        zipcode: [validator.rules.zipcode()],
        email: [validator.rules.email()],
      }
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    }),
    propertyId() {
      return this.currentApp.propertyId;
    },
    propertyName() {
      return this.currentApp.propertyName;
    },
    onboardingDocuments() {
      return this.currentApp.onboardingDocuments;
    },
    voidedCheckDocs() {
      return this.onboardingDocuments.filter(
        doc => doc.description == "voided_check"
      );
    },
    splitManagement() {
      return parseFloat(this.form.splitManagement);
    }
  },
  watch: {
    showDialog(val) {
      if (!val) {
        const _this = this;
        setTimeout(function() {
          _this.view = null;
        }, 300);
      }
    },
    splitManagement: function (val) {
      if (val>=0 && val<=100) {
        this.form.splitCommunity = 100 - val
      }
    },
  },
  methods: {
    isAdvancedUpload() {
      const div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    loadProperty() {
      const _this = this;
      this.$http
        .get("/manager/properties/pending/" + this.propertyId)
        .then(response => {
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](response.data);
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    uploadDoc(file) {
      const _this = this;
      this.isUploading = true;
      this.$http
        .post("/manager/uploadDocument", {
          propertyId: _this.propertyId,
          description: "voided_check",
          filename: file["name"],
          fileMimeType: file["type"],
          fileData: file["data"]
        })
        .then(() => {
          _this.loadProperty();
        })
        .finally(() => {
          _this.isUploading = false;
        });
    },
    viewDoc(id) {
      this.view = this.voidedCheckDocs.find(doc => doc.documentId == id);
      this.showDialog = true;
    },
    removeDoc(id) {
      const _this = this;
      this.$http
        .delete("/manager/documents/" + id)
        .then(() => {
          _this.loadProperty();
        })
        .catch(() => {});
    },
    submitForm() {
      const _this = this;
      if (!this.form.fundAccount) {
        return this.alertError("Please select a funding option.");
      }
      if (this.form.fundAccount === "ACH") {
        this.form.achComments = null
        if (!this.voidedCheckDocs.length || (this.voidedCheckDocs && this.voidedCheckDocs==0)) {
          this.alertError("Please upload the copy of a voided check.")
          return
        }
      } else {
        if (!this.form.fundAccount === 'Already on file') {
          this.form.achComments = null
        }
        this.form.achPrintedName = null
        this.form.achAccountType = null
        this.form.achInstitutionName = null
        this.form.achCity = null
        this.form.achState = null
        this.form.achZip = null
        this.form.achRoutingNumber = null
        this.form.achAccountNumber = null
      }
      if (!this.form.agreeACHTerms) {
        return this.alertError("You must agree the terms.");
      }
      if (!this.$refs.form.validate()) {
        return this.alertError("Please fill missing fields.");
      }

      this.currentApp.stepUsers = false
      this.currentApp.stepDocuments = false
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)
      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
          _this.$events.$emit("success", {
            step: _this.step
          });
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null) {
          this.form[key] = this.currentApp[key];
        }
      }
    },
    checkSplitRange(value) {
        if(!isNaN(parseFloat(value)) && value >= 0 && value <= 100){
            return true;
        }
        return 'Type a value between 0% and 100%';
    },
    autosave() {
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)
      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    }
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm();
  }
};
</script>
<style>
.has-advanced-upload {
  background-color: white;
  outline: 2px dashed #ccc;
  outline-offset: -20px;
  padding: 10px;
  min-height: 200px;
}

.has-advanced-upload .box__dragndrop {
  display: inline;
}

.is-dragover {
  background-color: grey;
}

.dialogViewer {
  height: 100%;
  padding-top: 64px;
}

.dialogViewer iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
</style>
