<template>
  <div>
    <v-card-text class="pt-0">
      <v-form ref="form">
        <v-container>
          <div class="mb-4">
            <h2 class="step-title">Pets information</h2>
          </div>
          <v-layout row wrap text-xs-center pb-5 v-if="isLoading">
            <v-flex>
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="!isLoading && currentApp.allowLeases">
            <v-flex xs12>
              Are lessees/tenants permitted to have pets?
              <v-radio-group v-model="form.leaseAllowPets" @change="resetPetValues()">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <template v-if="form.leaseAllowPets">
              <v-flex xs6>
                <div>Maximum allowed pet weight</div>
                <v-text-field
                  v-model="form.leaseMaxPetWeight"
                  type="number"
                  label="Add weight in pounds"
                  :disabled="form.leaseNoLimitPetNumber"
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div>Maximum number of pets</div>
                <v-text-field
                  v-model="form.leaseMaxPetLimit"
                  type="number"
                  label="Add pets limit"
                  :disabled="form.leaseNoLimitPetNumber"
                  box
                ></v-text-field>
                <!--<v-text-field v-show="form.leaseNoLimitPetNumber" value="∞"  label="Add pets limit" disabled solo box></v-text-field>-->
              </v-flex>
              <v-flex xs12 mb-3 class="check-nolimit">
                <v-checkbox
                  color="primary"
                  label="No Limit on Pet Weight or Pet Amount."
                  v-model="form.leaseNoLimitPetNumber"
                  @change="resetPetValues()"
                ></v-checkbox>
              </v-flex>
            </template>
          </v-layout>

          <v-layout row wrap v-if="!isLoading && currentApp.allowPurchases">
            <v-flex xs12>
              Are purchasers/owners permitted to have pets?
              <v-radio-group v-model="form.purchaseAllowPets" @change="resetPetValues()">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <template v-if="form.purchaseAllowPets">
              <v-flex xs6>
                <div>Maximum allowed pet weight</div>
                <v-text-field
                  v-model="form.purchaseMaxPetWeight"
                  type="number"
                  label="Add weight in pounds"
                  :disabled="form.purchaseNoLimitPetNumber"
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div>Maximum number of pets</div>
                <v-text-field
                  v-model="form.purchaseMaxPetLimit"
                  type="number"
                  label="Add pets limit"
                  :disabled="form.purchaseNoLimitPetNumber"
                  box
                ></v-text-field>
                <!--<v-text-field v-show="form.purchaseNoLimitPetNumber" value="∞"  label="Add pets limit" disabled solo box></v-text-field>-->
              </v-flex>
              <v-flex xs12 mb-3 class="check-nolimit">
                <v-checkbox
                  color="primary"
                  label="No Limit on Pet Weight or Pet Amount."
                  v-model="form.purchaseNoLimitPetNumber"
                  @change="resetPetValues()"
                ></v-checkbox>
              </v-flex>
            </template>
          </v-layout>

          <v-layout row wrap v-if="!isLoading && currentApp.allowGuests">
            <v-flex xs12>
              Are guests permitted to have pets?
              <v-radio-group v-model="form.guestAllowPets" @change="resetPetValues()">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
            <template v-if="form.guestAllowPets">
              <v-flex xs6>
                <div>Maximum allowed pet weight</div>
                <v-text-field
                  v-model="form.guestMaxPetWeight"
                  type="number"
                  label="Add weight in pounds"
                  :disabled="form.guestNoLimitPetNumber"
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <div>Maximum number of pets</div>
                <v-text-field
                  v-model="form.guestMaxPetLimit"
                  type="number"
                  label="Add pets limit"
                  :disabled="form.guestNoLimitPetNumber"
                  box
                ></v-text-field>
                <!--<v-text-field v-show="form.guestNoLimitPetNumber" value="∞"  label="Add pets limit" disabled solo box></v-text-field>-->
              </v-flex>
              <v-flex xs12 mb-3 class="check-nolimit">
                <v-checkbox
                  color="primary"
                  label="No Limit on Pet Weight or Pet Amount."
                  v-model="form.guestNoLimitPetNumber"
                  @change="resetPetValues()"
                ></v-checkbox>
              </v-flex>
            </template>
          </v-layout>

        </v-container>
      </v-form>
    </v-card-text>
  </div>
</template>
<script>
import Validator from "../../libs/validator";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  name: "PetsInformationStep",
  props: {
    step: Number
  },
  data() {
    const validator = new Validator();
    return {
      isLoading: false,
      form: {
        leaseAllowPets: false,
        leaseMaxPetWeight: null,
        leaseMaxPetLimit: null,
        leaseNoLimitPetNumber: false,
        purchaseAllowPets: false,
        purchaseMaxPetWeight: null,
        purchaseMaxPetLimit: null,
        purchaseNoLimitPetNumber: false,
        guestAllowPets: false,
        guestMaxPetWeight: null,
        guestMaxPetLimit: null,
        guestNoLimitPetNumber: false,
      },
      rules: {
        required: [validator.rules.required()]
      }
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    }),

    allowPets() {
      return this.currentApp[this.name + "AllowPets"];
    },
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    submitForm() {
      const _this = this;
      if (!this.$refs.form.validate()) {
        return;
      }

      if(this.form.leaseAllowPets) {
        if(this.form.leaseNoLimitPetNumber) {
          this.form.leaseMaxPetWeight = 999;
          this.form.leaseMaxPetLimit = 999;
        } else {
          if (!this.checkNumberRange(this.form.leaseMaxPetWeight) || !this.checkNumberRange(this.form.leaseMaxPetLimit)) {
            return this.alertError("Please enter pet values from 1 to 999 or check No limits for lease applications")
          }
        }
      }
      if(this.form.purchaseAllowPets) {
        if(this.form.purchaseNoLimitPetNumber) {
          this.form.purchaseMaxPetWeight = 999;
          this.form.purchaseMaxPetLimit = 999;
        } else {
          if (!this.checkNumberRange(this.form.purchaseMaxPetWeight) || !this.checkNumberRange(this.form.purchaseMaxPetLimit)) {
            return this.alertError("Please enter pet values from 1 to 999 or check No limits for purchase applications")
          }
        }
      }
      if(this.form.guestAllowPets) {
        if(this.form.guestNoLimitPetNumber) {
          this.form.guestMaxPetWeight = 999;
          this.form.guestMaxPetLimit = 999;
        } else {
          if (!this.checkNumberRange(this.form.guestMaxPetWeight) || !this.checkNumberRange(this.form.guestMaxPetLimit)) {
            return this.alertError("Please enter pet values from 1 to 999 or check No limits for guest applications")
          }
        }
      }


      this.currentApp.stepUsers = false
      this.currentApp.stepDocuments = false
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)

      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
          _this.$events.$emit("success", {
            step: _this.step
          });
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },

    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null && this.currentApp[key] !== 0) {
          this.form[key] = this.currentApp[key];
        }
      }
      if(this.form.leaseAllowPets && this.form.leaseMaxPetWeight==999) {
        this.form.leaseNoLimitPetNumber = true;
      }
      if(this.form.purchaseAllowPets && this.form.purchaseMaxPetWeight==999) {
        this.form.purchaseNoLimitPetNumber = true;
      }
      if(this.form.guestAllowPets && this.form.guestMaxPetWeight==999) {
        this.form.guestNoLimitPetNumber = true;
      }
    },
    checkNumberRange(value) {
      if(!isNaN(parseFloat(value)) && value > 0 && value <= 999){
          return true
      }
      return false
    },
    resetPetValues(){
      this.currentApp.leaseAllowPets = this.form.leaseAllowPets
      this.currentApp.purchaseAllowPets = this.form.purchaseAllowPets
      this.currentApp.guestAllowPets = this.form.guestAllowPets
      if(!this.form.leaseAllowPets || this.form.leaseNoLimitPetNumber){
        this.form.leaseMaxPetWeight = null;
        this.form.leaseMaxPetLimit = null;
      }
      if(!this.form.purchaseAllowPets || this.form.purchaseNoLimitPetNumber){
        this.form.purchaseMaxPetWeight = null;
        this.form.purchaseMaxPetLimit = null;
      }
      if(!this.form.guestAllowPets || this.form.guestNoLimitPetNumber){
        this.form.guestMaxPetWeight = null;
        this.form.guestMaxPetLimit = null;
      }
    },
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm();
  }
};
</script>
<style>
.v-toolbar--fixed {
  z-index: 3;
}

.layout-included {
  background-color: #fff;
  border-radius: 4px;
}

.layout-included > div {
  width: 30%;
  text-align: right;
}

.layout-included > div > svg {
  margin-left: auto;
  margin-right: 0px !important;
}

.text-muted {
  color: #696969;
}

.check-nolimit {
  margin-top: -50px;
}
</style>
