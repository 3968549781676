var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "pt-0" },
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-container",
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _c("h2", { staticClass: "step-title" }, [
                      _vm._v("Pets information"),
                    ]),
                  ]),
                  _vm.isLoading
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "text-xs-center": "",
                            "pb-5": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading && _vm.currentApp.allowLeases
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm._v(
                                "\n            Are lessees/tenants permitted to have pets?\n            "
                              ),
                              _c(
                                "v-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.resetPetValues()
                                    },
                                  },
                                  model: {
                                    value: _vm.form.leaseAllowPets,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "leaseAllowPets", $$v)
                                    },
                                    expression: "form.leaseAllowPets",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      color: "blue",
                                      label: "Yes",
                                      value: true,
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      color: "blue",
                                      label: "No",
                                      value: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.leaseAllowPets
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("div", [
                                      _vm._v("Maximum allowed pet weight"),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Add weight in pounds",
                                        disabled:
                                          _vm.form.leaseNoLimitPetNumber,
                                        box: "",
                                      },
                                      model: {
                                        value: _vm.form.leaseMaxPetWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "leaseMaxPetWeight",
                                            $$v
                                          )
                                        },
                                        expression: "form.leaseMaxPetWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("div", [
                                      _vm._v("Maximum number of pets"),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Add pets limit",
                                        disabled:
                                          _vm.form.leaseNoLimitPetNumber,
                                        box: "",
                                      },
                                      model: {
                                        value: _vm.form.leaseMaxPetLimit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "leaseMaxPetLimit",
                                            $$v
                                          )
                                        },
                                        expression: "form.leaseMaxPetLimit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "check-nolimit",
                                    attrs: { xs12: "", "mb-3": "" },
                                  },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        color: "primary",
                                        label:
                                          "No Limit on Pet Weight or Pet Amount.",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.resetPetValues()
                                        },
                                      },
                                      model: {
                                        value: _vm.form.leaseNoLimitPetNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "leaseNoLimitPetNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.leaseNoLimitPetNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.isLoading && _vm.currentApp.allowPurchases
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm._v(
                                "\n            Are purchasers/owners permitted to have pets?\n            "
                              ),
                              _c(
                                "v-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.resetPetValues()
                                    },
                                  },
                                  model: {
                                    value: _vm.form.purchaseAllowPets,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "purchaseAllowPets",
                                        $$v
                                      )
                                    },
                                    expression: "form.purchaseAllowPets",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      color: "blue",
                                      label: "Yes",
                                      value: true,
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      color: "blue",
                                      label: "No",
                                      value: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.purchaseAllowPets
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("div", [
                                      _vm._v("Maximum allowed pet weight"),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Add weight in pounds",
                                        disabled:
                                          _vm.form.purchaseNoLimitPetNumber,
                                        box: "",
                                      },
                                      model: {
                                        value: _vm.form.purchaseMaxPetWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "purchaseMaxPetWeight",
                                            $$v
                                          )
                                        },
                                        expression: "form.purchaseMaxPetWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("div", [
                                      _vm._v("Maximum number of pets"),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Add pets limit",
                                        disabled:
                                          _vm.form.purchaseNoLimitPetNumber,
                                        box: "",
                                      },
                                      model: {
                                        value: _vm.form.purchaseMaxPetLimit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "purchaseMaxPetLimit",
                                            $$v
                                          )
                                        },
                                        expression: "form.purchaseMaxPetLimit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "check-nolimit",
                                    attrs: { xs12: "", "mb-3": "" },
                                  },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        color: "primary",
                                        label:
                                          "No Limit on Pet Weight or Pet Amount.",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.resetPetValues()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.form.purchaseNoLimitPetNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "purchaseNoLimitPetNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.purchaseNoLimitPetNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.isLoading && _vm.currentApp.allowGuests
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm._v(
                                "\n            Are guests permitted to have pets?\n            "
                              ),
                              _c(
                                "v-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.resetPetValues()
                                    },
                                  },
                                  model: {
                                    value: _vm.form.guestAllowPets,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "guestAllowPets", $$v)
                                    },
                                    expression: "form.guestAllowPets",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      color: "blue",
                                      label: "Yes",
                                      value: true,
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      color: "blue",
                                      label: "No",
                                      value: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.guestAllowPets
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("div", [
                                      _vm._v("Maximum allowed pet weight"),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Add weight in pounds",
                                        disabled:
                                          _vm.form.guestNoLimitPetNumber,
                                        box: "",
                                      },
                                      model: {
                                        value: _vm.form.guestMaxPetWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "guestMaxPetWeight",
                                            $$v
                                          )
                                        },
                                        expression: "form.guestMaxPetWeight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("div", [
                                      _vm._v("Maximum number of pets"),
                                    ]),
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Add pets limit",
                                        disabled:
                                          _vm.form.guestNoLimitPetNumber,
                                        box: "",
                                      },
                                      model: {
                                        value: _vm.form.guestMaxPetLimit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "guestMaxPetLimit",
                                            $$v
                                          )
                                        },
                                        expression: "form.guestMaxPetLimit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "check-nolimit",
                                    attrs: { xs12: "", "mb-3": "" },
                                  },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        color: "primary",
                                        label:
                                          "No Limit on Pet Weight or Pet Amount.",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.resetPetValues()
                                        },
                                      },
                                      model: {
                                        value: _vm.form.guestNoLimitPetNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "guestNoLimitPetNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.guestNoLimitPetNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }