<template>
  <v-card-text class="pt-0">
    <v-form ref="form">
      <v-container class="credit-score">
        <div class="mb-4">
          <h2 class="step-title">Important Property Requirements</h2>
        </div>

        <v-layout row wrap>
          <v-flex xs12 mb-3>
            <p>
              Please include any important property requirements you want to inform the applicants
              <strong>before they apply</strong>. For example:
            </p>
            <ul>
              <li>Allowed Moving Days: Monday, Friday from 8 am to 5 pm</li>
              <li>An interview with the board is also part of the application process to obtain approval.</li>
              <li>A credit score of 649 is required.</li>
              <li>Common area security deposit of $2,000.00 made payable to the community is required, once tentatively approved.</li>
              <li>Elevator deposit of $1,000.00 required for move in.</li>
            </ul>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              :counter="1100"
              :rules="[v => (!v || (v && v.length <= 1100)) || 'Notes must be 1100 characters or less']"
              :autofocus="$isDesktop()"
              box
              label="Add notes here (Optional)"
              v-model="form.propertyNotes"
            ></v-textarea>
          </v-flex>
        </v-layout>

        <v-divider style="padding-top:25px;"></v-divider>

        <v-layout row wrap v-if="currentApp.allowLeases">
          <v-flex xs12><strong>Minimum Lease Length</strong></v-flex>
          <v-flex xs12 sm6 mb-1>
            <v-text-field
              v-model="form.leaseMin"
              type="number"
              label="Add number of months"
              :rules="rules.required"
              required
              box
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-divider style="padding-top:25px;"></v-divider>

        <div class="mb-4" style="padding-top:25px;">
          <h3>Credit Score</h3>
        </div>

        <v-layout row wrap>
          <v-flex xs12 mb-3>
            <v-flex xs12 mb-3>
              <strong>Does your property require a minimum credit score?</strong>
              <v-radio-group v-model="form.requireMinScore" v-on:change="changeRequire">
                <v-radio color="blue" label="Yes" :value="true"></v-radio>
                <v-radio color="blue" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-flex>
          <v-flex xs12 v-if="form.requireMinScore">
            <p>What will be the minimum average credit score for an applicant to be approved in your property</p>
            <p class="grey--text">Move the bar to setup the score or enter it manually</p>
            <v-flex xs12>
              <v-flex lg4 md4 sm12 offset-md4 offset-lg4 offset-sm0>
                <v-text-field
                  v-model="form.creditScore"
                  label="Credit Score"
                  single-line
                  required
                  box
                  type="number"
                  :max="maxCreditScore"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-flex>
            <v-flex xs12>
              <v-flex lg8 md8 sm12 offset-md2 offset-lg2 offset-sm0>
                <v-slider v-model="form.creditScore" :min="minCreditScore" :max="maxCreditScore"></v-slider>
              </v-flex>
            </v-flex>
            <v-flex xs12 mb-5>
              <v-flex lg8 md8 sm12 offset-md2 offset-lg2 offset-sm0>
                <v-layout align-content-space-around text-xs-center>
                  <v-flex xs3>Fair</v-flex>
                  <v-flex xs3>Good</v-flex>
                  <v-flex xs3>Very Good</v-flex>
                  <v-flex xs3>Excellent</v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-card-text>
</template>
<script>
import Validator from "../../libs/validator";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  name: "PropertyRequirementsStep",
  props: {
    step: Number
  },
  data() {
    const validator = new Validator();
    return {
      minCreditScore: 450,
      maxCreditScore: 800,
      form: {
        propertyId: null,
        propertyNotes: "",
        leaseMin: null,
        requireMinScore: null,
        creditScore: 620
      },
      rules: {
        required: [validator.rules.required()]
      }
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    })
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    submitForm() {
      const _this = this;
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.form.requireMinScore==null) {
        return this.alertError("Please select a Credit Score option.")
      }

      this.currentApp.stepUsers = false
      this.currentApp.stepDocuments = false
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)
      this.$http
        .post("/manager/properties/pending", this.currentApp)
        .then(() => {
          _this.$events.$emit("success", {
            step: _this.step
          });
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null) {
          this.form[key] = this.currentApp[key];
        }
      }
    },
    changeRequire() {
      if (this.form.requireMinScore) {
        if (this.form.creditScore == null) {
          this.form.creditScore = 620;
        }
      } else {
        this.form.creditScore = null;
      }
    }
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm();
  }
};
</script>
<style>
.nocreditscore {
  opacity: 0.4;
  pointer-events: none;
}
</style>
