<template>
  <v-card-text class="pt-0">
    <v-form ref="form">
      <v-container>
        <div class="mb-4">
          <h2 class="step-title">Community Information</h2>
        </div>
        <v-layout row wrap>
          <v-alert v-if="isBlacklisted"
            :value="true"
            color="warning"
            icon="warning"
            outline
          >
            Some of the Information entered above has been flagged down on the Credit Bureau's Blacklist! In accordance to FCRA regulations, our team is required to conduct further research. A Sale representative will contact you as soon as possible with additional information. Thank you!
          </v-alert>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 sm12>
            <v-text-field
              browser-autocomplete="off"
              v-model="form.propertyName"
              label="Community Name"
              :rules="rules.required"
              :autofocus="$isDesktop()"
              required
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <v-autocomplete
              v-model="form.businessOrigin"
              browser-autocomplete="off"
              :items="states"
              item-text="name"
              item-value="abbr"
              label="State where incorportated"
              no-data-text="State not found."
              persistent-hint
              :rules="rules.required"
              box
            ></v-autocomplete>
          </v-flex>
          <v-flex xs12 sm6>
            <v-select
              v-model="form.entityType"
              :items="entityTypes"
              label="Business / Entity Type"
              :rules="rules.required"
              required
              box
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              browser-autocomplete="off"
              v-model="form.einNumber"
              v-on:change="einChange"
              label="FEI / EIN number*"
              :rules="rules.einNumber"
              required
              box
            >
            <!--v-tooltip slot="append" bottom>
              <v-icon slot="activator" color="primary" dark>home</v-icon>
              <span>Tooltip</span>
            </v-tooltip-->
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <span class="v-messages theme--light small-hint"><strong>FEI / EIN number*</strong> Federal Employer Identification No / Employer Identification No. You can locate your FEI / EIN on your confirmation letter from the IRS, tax returns, payroll paperwork, etc.</span>
          </v-flex>

          <v-flex xs12 v-if="onAutoAddress">
            <vuetify-google-autocomplete
              ref="form.address"
              id="map"
              classname="form-control"
              placeholder="Please type your address"
              v-on:placechanged="getAddressData"
              country="us"
              label="Address"
              required
              box
            >
            </vuetify-google-autocomplete>
         </v-flex>

          <template v-if="!onAutoAddress">
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.street"
                label="Mailing address"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.city"
                label="City"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-autocomplete
                v-model="form.state"
                browser-autocomplete="off"
                :items="states"
                item-text="name"
                item-value="abbr"
                label="State"
                no-data-text="State not found."
                persistent-hint
                :rules="rules.required"
                box
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.zipcode"
                label="Zip Code"
                :rules="rules.zipcode"
                required
                box
              ></v-text-field>
            </v-flex>
          </template>
          <v-flex xs12 v-if="!isGoogleAutocompleteDisabled">
            <template v-if="onAutoAddress">
              <a class="link-form" href="#" @click="onAutoAddress=false">or enter address manually</a>
            </template>
            <template v-else>
              <a class="link-form" href="#" @click="onAutoAddress=true">or enter address using google suggestions</a>
            </template>
          </v-flex>

          <v-flex xs12 sm6>
            <v-text-field
              browser-autocomplete="off"
              v-model="form.propertyPhone"
              label="Community main phone number"
              :rules="rules.phone"
              required
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              browser-autocomplete="off"
              type="number"
              v-model="form.propertyPhoneExt"
              label="Community phone extension (optional)"
              box
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6>
            <v-text-field
              type="number"
              browser-autocomplete="off"
              v-model="form.businessYears"
              :rules="rules.required"
              label="Years in business*"
              required
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              type="number"
              browser-autocomplete="off"
              v-model="form.unitsNumber"
              :rules="rules.required"
              label="Number of units*"
              required
              box
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12>
            <v-text-field
              browser-autocomplete="off"
              v-model="form.businessWebsite"
              label="Website (optional)"
              box
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6>
            <v-text-field
              browser-autocomplete="off"
              v-model="form.entityId"
              label="Entity Id (optional)"
              :rules="rules.entityId"
              box
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <span class="v-messages theme--light small-hint"><strong>Entity ID</strong> You can add any internal code your organization uses to track this community for accounting purposes. This code will be reflected on the community reports.</span>
          </v-flex>
        </v-layout>

        <v-layout class="layout-sunbiz" row wrap text-xs-center pb-5 v-if="isLoading">
          <div class="border-sunbiz">
            <v-flex xs12 mb-2 pt-2>
              <img src="../../assets/upload-doc.svg" class="mr-2" height="40" />
            </v-flex>

            <v-flex xs12 mb-2 pt-2>
              <strong>Upload a copy of the articles of incorporation, in addition send a copy of you current annual report or EIN number issue letter.</strong>
            </v-flex>
            <v-flex xs12 mb-2 pt-2>
              <v-progress-circular :size="50" color="primary" indeterminate />
            </v-flex>
            <v-flex  xs12 mb-2 pt-2>
              Please wait, we are uploading articles of incorporation and annual report of your company
            </v-flex>
            <v-flex  xs12 mb-2 pt-2>
              <v-btn flat small outline color="primary" @click="cancelDocs()">Cancel</v-btn>
            </v-flex>
          </div>
        </v-layout>

        <v-layout class="layout-sunbiz" text-xs-center row wrap v-if="!isLoading">
          <div class="border-sunbiz">
            <v-flex xs12 mb-2 pt-2>
              <img src="../../assets/upload-doc.svg" class="mr-2" height="40" />
            </v-flex>

            <v-flex xs12 mb-2>
              <strong>Articles of Incorporation and Annual Report / EIN number issue letter</strong>
            </v-flex>
            <template v-if="!(form.businessOrigin=='FL')">
              <v-flex
                xs12
                mb-4
              >Please upload a copy of the articles of incorporation and annual report from the Division of Corporations of the State where incorportated.</v-flex>
            </template>
            <template v-if="(form.businessOrigin=='FL')&&!hasDocs">
              <v-flex
                xs12
                mb-2
              >Please upload a copy of the articles of incorporation and annual report from the Division of Corporations of the State where incorporated. </v-flex>
              <v-flex xs12 mb-4>You can get a copy of the articles of incorporation and annual report from the following link or click Download button</v-flex>
              <v-flex xs12 mb-4>
                <a href="http://search.sunbiz.org" target="_blank">search.sunbiz.org</a>
              </v-flex>
              <v-flex xs12 mb-4>
                <v-btn flat small outline color="primary" @click="checkDocs()">Download</v-btn>
              </v-flex>
            </template>
            <template v-if="(form.businessOrigin=='FL')&&hasDocs">
              <v-flex
                xs12
                mb-2
              >We have detected the company was created in Florida and through the EIN number we have automatically attached the Annual Report and Articles of Incorporation.</v-flex>
              <v-flex xs12 mb-4>Please confirm all the information is correct.</v-flex>
            </template>
            <template v-if="annualReportDocs.length">
              <v-flex
                xs12
                mt-3
                mb-4
                text-xs-center
                v-bind:key="annualReportDocs[0].documentId"
              >
                <h3 class="mr-3">
                  <v-icon>attachment</v-icon>Annual Report / EIN number issue letter
                </h3>
                <v-btn flat small outline color="primary" @click="viewDoc(annualReportDocs[0].documentId)">View</v-btn>
                <v-btn flat small outline color="primary" @click="removeDoc(annualReportDocs[0].documentId)">Remove</v-btn>
              </v-flex>
            </template>
            <template v-if="incorporationDocs.length">
              <v-flex
                xs12
                mt-3
                mb-4
                text-xs-center
                v-bind:key="incorporationDocs[0].documentId"
              >
                <h3 class="mr-3">
                  <v-icon>attachment</v-icon>Articles of Incorporation
                </h3>
                <v-btn flat small outline color="primary" @click="viewDoc(incorporationDocs[0].documentId)">View</v-btn>
                <v-btn flat small outline color="primary" @click="removeDoc(incorporationDocs[0].documentId)">Remove</v-btn>
              </v-flex>
            </template>
            <v-flex xs12 pb-4 v-if="!annualReportDocs.length">
              <h3>Annual Report / EIN number issue letter</h3>
              <UploadButton
                large
                depressed
                v-bind:title="'Upload'"
                v-bind:multiple="false"
                v-bind:done="uploadAnnualReport"
                v-if="!isUploading.annual_report"
              ></UploadButton>
              <v-btn
                large
                depressed
                color="primary"
                :loading="true"
                v-bind:class="{'blue--text': true}"
                :disabled="true"
                v-if="isUploading.annual_report"
              ></v-btn>
            </v-flex>
            <v-flex xs12 pb-5 v-if="!incorporationDocs.length">
              <h3>Articles Of Incorporation</h3>
              <UploadButton
                large
                depressed
                v-bind:title="'Upload'"
                v-bind:multiple="false"
                v-bind:done="uploadArticlesIncorporation"
                v-if="!isUploading.incorporation"
              ></UploadButton>
              <v-btn
                large
                depressed
                color="primary"
                :loading="true"
                v-bind:class="{'blue--text': true}"
                :disabled="true"
                v-if="isUploading.incorporation"
              ></v-btn>
            </v-flex>
          </div>
        </v-layout>

        <v-layout pt-4 row wrap>
          <v-flex xs12 pb-3><strong>Please add the name, email, and position of the authorized individuals who will access the account*</strong></v-flex>

          <v-flex xs12 mt-2 mb-3>
            <v-layout row wrap v-for="(item, index) in form.users" v-bind:key="index">
              <v-flex xs12>
                <v-layout flex blue--text align-center justify-space-between>
                  <span>User No{{index+1}}</span>
                  <v-btn flat icon @click="removeUser(index)" color="grey">
                    <v-icon class="rotate">delete</v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 class="pr-3">
                <v-text-field
                  browser-autocomplete="off"
                  v-model="item.name"
                  label="Complete name"
                  :rules="rules.required"
                  required
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="pr-3">
                <v-select
                  v-model="item.position"
                  :items="userTypes"
                  label="Position"
                  :rules="rules.required"
                  required
                  box
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 class="pr-3">
                <v-text-field
                  browser-autocomplete="off"
                  v-model="item.email"
                  label="Email"
                  :rules="rules.email"
                  required
                  box
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 class="pr-3">
                <v-text-field
                  browser-autocomplete="off"
                  v-model="item.phone"
                  label="Phone number"
                  :rules="rules.phone"
                  required
                  box
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-flex xs12 v-if="totalUsers<5">
              <div v-ripple class="d-inline-block pr-3 rounded" @click="addNewUser">
                <v-btn color="primary" fab small dark depressed>
                  <v-icon>add</v-icon>
                </v-btn>
                <a class="ml-1">Add User</a>
              </div>
            </v-flex>
          </v-flex>




          <v-flex
            xs12
            pb-2
            style="font-size:14px;padding:30px 0px;"
          >*Please be informed that users are not allowed to share accounts for security reasons. If there is a change, please make sure to notify Tenant Evaluation within 1 to 3 business days.</v-flex>
        </v-layout>


      </v-container>
    </v-form>

    <v-dialog v-model="showDialog" :fullscreen="true">
      <v-card class="dialogViewer">
        <v-toolbar fixed>
          <v-toolbar-title>File Preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <transition name="fade">
          <iframe :src="view.documentUrl" v-if="view"></iframe>
        </transition>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBlacklistWarning" width="600">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          <span>Warning</span>
          <v-spacer />
          <!--v-btn icon color="white" flat @click="showBlacklistWarning = false">
            <v-icon>close</v-icon>
          </v-btn-->
        </v-card-title>
        <v-card-text>Some of the Information entered above has been flagged down on the Credit Bureau's Blacklist! In accordance to FCRA regulations, our team is required to conduct further research. A Sale representative will contact you as soon as possible with additional information. Thank you! </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showBlacklistWarning = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card-text>
</template>
<script>
import config from '@/config';

import UploadButton from "../../components/UploadButton";
import StatesJson from "../../states_hash.json";
import Validator from "../../libs/validator";
import sunbiz from "../../libs/sunbiz"
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES, ENTITY_TYPES, USER_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";
import axios from 'axios'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

export default {
  mixins: [DisableAutocomplete],
  name: "CommunityInformationStep",
  props: {
    step: Number
  },
  components: {
    UploadButton, VuetifyGoogleAutocomplete
  },
  data() {
    const validator = new Validator();
    return {
      states: StatesJson,
      isLoading: false,
      showDialog: false,
      showBlacklistWarning: false,
      isBlacklisted: false,
      onAutoAddress: true,
      isGoogleAutocompleteDisabled: false,
      isUploading: {
        annual_report: false,
        incorporation: false
      },
      hasDocs: false,
      view: null,
      entityTypes: ENTITY_TYPES,
      userTypes: USER_TYPES,
      einNumberDownloaded: null,
      form: {
        disablePricingInformation: false,
        propertyName: "",
        entityType: "",
        einNumber: "",
        businessOrigin: "",
        houseNo: "",
        street: "",
        streetType: "",
        apartmentNo: "",
        city: "",
        state: "",
        zipcode: "",

        businessYears: "",
        unitsNumber: "",
        businessEmail: "",
        businessWebsite: "",

        propertyPhone: "",
        propertyPhoneExt: "",
        accountsPayableName: "",
        accountsPayableEmail: "",
        pmFirstName: "",
        pmLastName: "",
        pmEmail: "",
        pmPhone: "",
        entityId: "",
        notifyName: "",
        notifyEmail: "",

        authUserName: "",
        authUserEmail: "",

        users: [],
        address: ""
      },
      rules: {
        required: [validator.rules.required()],
        zipcode: [validator.rules.zipcode()],
        einNumber: [validator.rules.einNumber()],
        email: [validator.rules.email()],
        phone: [validator.rules.phone()],
        entityId: [validator.rules.maxLength(25, "Entity Id length should not be more than 25"),]
      }
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    }),
    onboardingDocuments() {
      return this.currentApp.onboardingDocuments || [];
    },
    annualReportDocs() {
      return this.onboardingDocuments.filter(
        doc => doc.description == "annual_report"
      );
    },
    incorporationDocs() {
      return this.onboardingDocuments.filter(
        doc => doc.description == "incorporation"
      );
    },
    valid() {
      return this.annualReportDocs.length && this.incorporationDocs.length;
    },
    totalUsers() {
      return this.form.users ? this.form.users.length : 0
    },
  },
  watch: {
    showDialog(val) {
      if (!val) {
        const _this = this;
        setTimeout(function() {
          _this.view = null;
        }, 300);
      }
    }
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },

    async submitForm() {
      const _this = this;

      if (this.isBlacklisted) {
        this.showBlacklistWarning = true
        return
      }
      if(!this.form.city) {
        this.onAutoAddress = false
        return this.alertError("Please enter the city name.")
      }
      if(!this.form.zipcode) {
        this.onAutoAddress = false
        return this.alertError("Please enter the zip code.")
      }
      if(!this.form.street) {
        this.onAutoAddress = false
        return this.alertError("Please enter the street address.")
      }
      if(!this.form.state) {
        this.onAutoAddress = false
        return this.alertError("Please enter the State.")
      }

      if(this.form.disablePricingInformation) {
        this.form.accountsPayableName = "N/A";
        this.form.accountsPayableEmail = "N/A";
      }
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.annualReportDocs.length) {
        this.alertError("Please upload a copy of the annual report.")
        return
      }

      if(!this.incorporationDocs.length) {
        this.alertError("Please upload a copy of the articles of incorporation.")
        return
      }

      if (this.form.users.length == 0){
        this.alertError("Please provide the contact details of a user who will be assigned to the property.")
        return
      }else {
        const hasPropertyManager = this.form.users.some(user => user.position === 'Property Manager');
        if (!hasPropertyManager){
          this.alertError("Please provide contact details of at least one property manager.")
          return
        }
      }

      this.currentApp.stepUsers = true
      this.currentApp.stepDocuments = false
      delete this.currentApp.stepUser
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)

      let usersData = {
        subscriberId: this.currentApp.subscriberId,
        users: this.currentApp.users
      }

      /*usersData.subscriberId = 5;
      usersData.users.push({
        name:"bggasdf",
        email:"vg@test.com"
      });*/

      const headers = {
        headers: {
            Authorization: 'Bearer ' + localStorage.token
        }
      };
      axios
        .post(`${config.userRestUrl}check-user-subscriber`, usersData, headers)
        .then((response) => {
          const results = response.data;
          let hasError = false
          results.forEach(item => {
            if (item.messageError!==null) {
              _this.alertError(item.messageError)
              hasError = true
            }
          });
          if (hasError) {
            return
          }

          _this.$http
            .post("/manager/properties/pending", _this.currentApp)
            .then(() => {
              // Reload currentApp to get userIds
              _this.reloadUsers();
              _this.$events.$emit("success", {
                step: _this.step
              });
            })
            .catch(e => {
              //this.loginFailed(e.error || 'An error occurred while processing your request.')
              // eslint-disable-next-line
              console.error(e);
            })
            .finally(() => {
              _this.isLoading = false;
            });
        })
        .catch(e => {
          console.error(e);
          _this.alertError("A user already exists with a different subscriber, please enter a new one.")
          return
        })
        .finally(() => {

        });


    },
    loadProperty() {
      const _this = this;
      this.$http
        .get("/manager/properties/pending/" + this.currentApp.propertyId)
        .then(response => {
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](response.data);
          _this.isLoading = false
          _this.isUploading['annual_report'] = false
          _this.isUploading['incorporation'] = false
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.isLoading = false
          _this.isUploading['annual_report'] = false
          _this.isUploading['incorporation'] = false
        });
    },
    reloadUsers() {
      const _this = this;
      this.$http
        .get("/manager/properties/pending/" + this.currentApp.propertyId)
        .then(response => {
          if (response.data && response.data.users) {
            _this.currentApp.users = response.data.users
            _this.form.users = response.data.users
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },

    async uploadAnnualReport(file) {
      this.isUploading['annual_report'] = true
      await sunbiz.uploadDoc(file, "annual_report", this.currentApp.propertyId, this.$http)
      this.loadProperty()
      this.isUploading['annual_report'] = false
    },
    async uploadArticlesIncorporation(file) {
      this.isUploading['incorporation'] = true
      await sunbiz.uploadDoc(file, "incorporation", this.currentApp.propertyId, this.$http)
      this.loadProperty()
      this.isUploading['incorporation'] = false
    },
    async removeDoc(id) {
      await sunbiz.removeDoc(id, this.$http)
      this.loadProperty()
      this.hasDocs = false
    },
    async removeDocs(id1, id2) {
      await sunbiz.removeDocPromise(id1, this.$http)
      await sunbiz.removeDocPromise(id2, this.$http)
      this.loadProperty()
      this.hasDocs = false
    },
    async removeDocsAndUpload(id1, id2, isFloridaCo) {
      await sunbiz.removeDocPromise(id1, this.$http)
      await sunbiz.removeDocPromise(id2, this.$http)
      this.annualReportDocs.length = 0;
      this.incorporationDocs.length = 0;
      this.hasDocs = false
      this.uploading(isFloridaCo)
      this.loadProperty()
    },
    async cancelDocs(){
      this.isLoading = false
      this.isUploading['annual_report'] = false
      this.isUploading['incorporation'] = false
    },

    viewDoc(id) {
      this.view = this.onboardingDocuments.find(doc => doc.documentId == id);
      this.showDialog = true;
    },
    async checkDocs() {
      this.hasDocs = this.annualReportDocs.length > 0 && this.incorporationDocs.length > 0

      let isFloridaCo = (this.form.businessOrigin == "FL")
      this.uploading(isFloridaCo);
      if (!this.form.einNumber && isFloridaCo && this.hasDocs) {
        this.removeDocs(this.annualReportDocs[0].documentId, this.incorporationDocs[0].documentId)
      }
      if (this.form.einNumber && isFloridaCo && this.hasDocs) {
        this.removeDocsAndUpload(this.annualReportDocs[0].documentId, this.incorporationDocs[0].documentId, isFloridaCo)
      }
    },

    einChange() {
      this.checkDocs()
    },

    async uploading(isFloridaCo) {
      if (this.form.einNumber && isFloridaCo && !this.hasDocs) {
        this.isLoading = true
        this.isUploading['annual_report'] = true
        this.isUploading['incorporation'] = true

        let errorDocs = await sunbiz.getSunbizDocs(this.form.einNumber, this.currentApp.propertyId,
                        this.annualReportDocs, this.incorporationDocs, this.$http)
        if (!errorDocs) {
          this.hasDocs = true
          this.loadProperty()
        } else {
          this.alertError(errorDocs)
          this.isLoading = false
          this.isUploading['annual_report'] = false
          this.isUploading['incorporation'] = false
        }
      }
    },

    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null) {
          this.form[key] = this.currentApp[key];
        }
      }
    },

    addNewUser() {
      this.form.users.push({
        id: null,
        name: "",
        position: "Property Manager",
        email: "",
        phone: ""
      })
    },

    removeUser(index) {
      if (this.form.users[index].id) {
        const _this = this;
        this.$http
          .delete("/manager/delete/user-onboard/" + this.form.users[index].id)
          .then(() => {
            _this.form.users.splice(index, 1)
          })
      } else {
        this.form.users.splice(index, 1)
      }
    },

    blacklistCheck(textSearched, fieldName) {
      const _this = this;
      if (!textSearched) return
      else if (textSearched.length < 4) return

      axios.get('https://clientev.geetsi.es/api/blacklist-search?query=' + encodeURIComponent(textSearched))
        .then(response => {
          if (response.data && response.data.status=="FOUND") {
            const payload = {
              propertyId: _this.currentApp.propertyId,
              db: response.data.db,
              file: response.data.file,
              textSearched,
              fieldName,
              status: response.data.status,
              companies: []
            }

            for (let i in response.data.results) {
              const company = response.data.results[i].join()
              payload.companies.push(company)
            }

            _this.$http
              .post("/manager/blacklist", payload)
              .then(() => {

              })
              .catch(e => {
                console.error(e);
              })
              .finally(() => {
              _this.isBlacklisted = true
              _this.showBlacklistWarning = true
            });
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    getAddressData(addressData) {
      if (addressData) {
        this.form.street = addressData.name
        this.form.city = addressData.locality
        this.form.state = addressData.administrative_area_level_1
        this.form.zipcode = addressData.postal_code
      }
    },
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm()
    this.checkDocs()
    if (this.form.street) {
      this.onAutoAddress = false
    }

    if (config.disableGoogleAutocomplete) {
      console.log('Google autocomplete disabled')
      this.onAutoAddress = false
      this.isGoogleAutocompleteDisabled = true
    }
  }
};
</script>
<style>
.layout-sunbiz {
  margin: 30px 0px!important;
}

.border-sunbiz {
  border: 1px solid #d5d5d5;
  padding: 30px 60px;
  margin: 0px 8px;
}

.small-hint {
  margin-top: 10px;
}

.link-form {
  padding-left: 10px;
  margin-top: -25px;
  position: absolute;
}

.v-snack__content {
  height: auto!important;
}
</style>
