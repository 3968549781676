<template>
  <v-card-text class="pt-0">
    <v-form ref="form" v-if="!agreementUrl">
      <v-container>
        <div class="mb-4">
          <h2 class="step-title">Sign and agree</h2>
        </div>

        <div class="text-xs-center pa-5" v-if="isSubmitting">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>

        <v-layout row wrap v-if="!isSubmitting">
          <v-flex xs12 sm12 mb-4>
            <p>Before continuing please read carefully the following sections in order to process your new account application.</p>
            <v-flex>
              <v-layout
                row
                wrap
                align-center
                justify-start
                class="row-terms"
                v-for="(item, index) in sections"
                v-bind:key="item[1]"
              >
                <CheckMark :size="26" :delay="index*300" class="mr-3" style="flex: unset!important;" />
                <v-flex text-xs-left>{{item[0]}}</v-flex>
                <v-flex text-xs-right>
                  <v-btn round outline small color="primary" @click="openDialog(item)">Read</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-layout row wrap mt-5 mb-3 pa-2 white>
              <v-flex text-xs-center>
                <v-btn
                  v-if="currentApp.proposalDocUrl"
                  @click="showProposal=true"
                  class="secondary"
                  :disabled="showProposal"
                  :loading="showProposal"
                >
                  <img src="../../assets/icon-doc.svg" class="mr-2" height="24" /> Proposal
                </v-btn>
                <v-btn
                  @click="previewAgreement()"
                  class="primary"
                  labelClass="white--text"
                  :disabled="isLoading||showAgreement"
                  :loading="isLoading||showAgreement"
                >
                  <img src="../../assets/icon-doc.svg" class="mr-2" height="24" /> Preview agreement
                </v-btn>
              </v-flex>
            </v-layout>

            <p class="mt-5">
              <strong class="title">Are you authorized to review and sign this Membership Agreement?</strong>
            </p>

            <v-radio-group v-model="isAuthSign">
              <v-radio color="blue" label="Yes" :value="true"></v-radio>
              <v-radio color="blue" label="No" :value="false"></v-radio>
            </v-radio-group>

            <p
              class="mt-3"
              v-if="isAuthSign === false"
            >If not, please provide the following information for the person responsible. This person will get an invitation email to review and sign the final Membership Agreement.</p>
            <p class="mt-3" v-if="isAuthSign">Please provide the following information.</p>
            <p
              class="mt-3"
              v-if="isAuthSign"
            >I acknowledge that I have read and understood the preceding sections. I agree to abide by the Membership Agreement terms and to adhere to all the security policies herein instituted for the safety of the personal sensitive information of our consumers. This Membership Agreement Acknowledgement Form serves as acknowledgment to the full Membership Agreement which includes the sections listed previously.</p>
          </v-flex>
          <template v-if="isAuthSign !== null">
            <v-flex xs12 sm6>
              <v-text-field
                :autofocus="$isDesktop()"
                browser-autocomplete="off"
                v-model="form.signerName"
                label="First and Last Name"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.signerTitle"
                label="Title"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
          </template>
          <template v-if="isAuthSign === false">
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.signerEmail"
                label="Signer Email"
                :rules="rules.required"
                required
                box
              ></v-text-field>
            </v-flex>
          </template>
          <template v-if="isAuthSign">
            <v-flex xs12 sm6>
              <v-text-field
                browser-autocomplete="off"
                v-model="form.signingDate"
                label="Date"
                append-icon="event"
                readonly
                box
              ></v-text-field>
            </v-flex>
            <!--v-flex xs12>
              <img v-bind:src="form.signature" class="img-border"/>
            </v-flex-->
            <v-flex xs12>
              <p>I acknowledge and agree that this Agreement may be executed by electronic signature, which shall be considered as an original signature for all purposes and shall have the same force and effect as an original signature.</p>

              <div class="white pa-3">
                <TeSignaturePad
                  ref="TeSignaturePad"
                  @onSignature="onSignatureChange"
                  :signatureText="form.signerName"
                ></TeSignaturePad>
                <!--VueSignaturePad id="signature" width="100%" height="200px" ref="signaturePad" :options="{ onBegin, onEnd }" /-->
                <div class="v-text-field__details" v-if="signError">
                  <div class="v-messages theme--light error--text">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">Signature is required.</div>
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
    </v-form>

    <v-dialog v-model="showProposal" :fullscreen="true">
      <v-card class="dialog-document">
        <v-toolbar fixed>
          <v-toolbar-title>Proposal</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="white" flat @click="showProposal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe :src="currentApp.proposalDocUrl" v-if="currentApp.proposalDocUrl"></iframe>
        <v-btn
          large
          depressed
          color="primary"
          class="btn-toolbar"
          @click="showProposal = false"
        >Close</v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPreview" :fullscreen="true">
      <v-card class="dialog-document">
        <v-toolbar fixed>
          <v-toolbar-title>Preview Agreement</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="white" flat @click="showPreview = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-xs-center pa-5" v-if="isLoading">
          <v-progress-circular :size="50" color="primary" indeterminate />
        </v-card-text>
        <iframe :src="previewUrl" v-if="!isLoading && previewUrl"></iframe>
        <v-btn
          large
          depressed
          color="primary"
          class="btn-toolbar"
          v-if="!isLoading"
          @click="showPreview = false"
        >Close</v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAgreement" :fullscreen="true">
      <v-card class="dialog-agreement">
        <template v-if="!complete">
          <v-toolbar fixed>
            <v-toolbar-title>Review Agreement</v-toolbar-title>
          </v-toolbar>
          <iframe :src="agreementUrl" v-if="agreementUrl"></iframe>
          <v-btn
            large
            depressed
            color="primary"
            class="btn-toolbar"
            @click="complete = true"
          >Continue</v-btn>
        </template>
        <template v-else>
          <tev-navbar title="Success" />
          <v-form>
            <v-container>
              <v-flex xs12 headline mt-4 mb-5 black--text>
                Thank you!
                <br />You have successfully created your account
              </v-flex>
              <v-layout wrap row text-xs-center>
                <v-flex xs12>
                  <a :href="agreementUrl" :download="agreementFilename" target="_blank">
                    <svg
                      class="pulse-svg mb-5"
                      width="50px"
                      height="50px"
                      viewBox="0 0 50 50"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <circle
                        class="circle first-circle"
                        fill="#DDF0FF"
                        cx="25.109"
                        cy="50"
                        r="50"
                      />
                      <circle
                        class="circle second-circle"
                        fill="#DDF0FF"
                        cx="25.109"
                        cy="50"
                        r="50"
                      />
                      <circle
                        class="circle third-circle"
                        fill="#DDF0FF"
                        cx="25.109"
                        cy="50"
                        r="50"
                      />
                      <circle class="circle-button" fill="#DDF0FF" cx="25.109" cy="45.01" r="46" />
                      <g transform="translate(0)" class="circle-icon">
                        <g transform="translate(17.227 24.219)">
                          <path
                            fill="#2196F3"
                            d="M3.827,34.672c-0.459,0-0.832-0.373-0.832-0.832c0-0.002,0-0.002,0-0.002v-10
                      c-0.001-0.459,0.371-0.834,0.83-0.834c0,0,0.001,0,0.002,0h10c0.46,0.002,0.831,0.379,0.829,0.838
                      c-0.003,0.457-0.373,0.826-0.829,0.83H4.661v9.168c0,0.461-0.374,0.834-0.834,0.834V34.672z"
                          />
                        </g>
                        <path
                          fill="#2196F3"
                          d="M21.054,58.891H3.834C3.374,58.891,3,58.518,3,58.057V23.832C3,23.371,3.373,23,3.832,23
                    c0,0,0.001,0,0.002,0h27.229c0.459-0.002,0.833,0.369,0.834,0.83c0,0,0,0,0,0.002v24.221c0,0.221-0.088,0.434-0.245,0.59l-10,10
                    c-0.156,0.156-0.369,0.244-0.591,0.244L21.054,58.891z M4.663,57.223h16.046l9.515-9.516V24.666H4.664L4.663,57.223L4.663,57.223z
                    "
                        />
                        <g transform="translate(5.627 6.322)">
                          <path
                            fill="#2196F3"
                            d="M13.623,24.668H3.829c-0.46,0.004-0.836-0.367-0.839-0.828c-0.003-0.461,0.368-0.836,0.829-0.84
                      c0.003,0,0.007,0,0.01,0h9.794c0.46-0.002,0.836,0.369,0.839,0.828c0.003,0.461-0.368,0.838-0.829,0.84
                      C13.63,24.668,13.626,24.668,13.623,24.668z"
                          />
                        </g>
                        <g transform="translate(5.627 10.353)">
                          <path
                            fill="#2196F3"
                            d="M20.084,24.667H3.829c-0.46,0-0.833-0.373-0.833-0.834s0.373-0.834,0.833-0.834h16.255
                      c0.46,0,0.833,0.373,0.833,0.834S20.544,24.667,20.084,24.667z"
                          />
                        </g>
                        <g transform="translate(5.627 14.382)">
                          <path
                            fill="#2196F3"
                            d="M20.084,24.669H3.829c-0.46,0.002-0.836-0.369-0.839-0.83c-0.003-0.459,0.368-0.836,0.829-0.838
                      c0.003,0,0.007,0,0.01,0h16.255c0.461,0.002,0.832,0.379,0.828,0.838C20.911,24.296,20.54,24.665,20.084,24.669z"
                          />
                        </g>
                        <g transform="translate(5.627 18.41)">
                          <path
                            fill="#2196F3"
                            d="M10.634,24.668h-6.8c-0.46-0.002-0.832-0.379-0.829-0.84C3.008,23.373,3.377,23.002,3.834,23h6.8
                      c0.46,0.004,0.832,0.379,0.829,0.84C11.46,24.295,11.091,24.666,10.634,24.668z"
                          />
                        </g>
                        <g transform="translate(24.651 24.427)">
                          <g transform="translate(0 11.415)">
                            <path
                              fill="#2196F3"
                              d="M12.78,31.178c-0.176,0-0.347-0.057-0.489-0.158l-8.947-6.514c-0.373-0.27-0.455-0.791-0.185-1.162
                        c0.271-0.373,0.792-0.455,1.164-0.186l0,0l8.457,6.154l8.463-6.154c0.372-0.27,0.894-0.188,1.164,0.186
                        c0.271,0.371,0.188,0.893-0.185,1.162l-8.951,6.514C13.129,31.123,12.957,31.178,12.78,31.178L12.78,31.178z"
                            />
                          </g>
                          <g transform="translate(8.949)">
                            <path
                              fill="#2196F3"
                              d="M3.827,42.593c-0.46,0-0.832-0.371-0.832-0.832c0,0,0,0,0-0.002V23.835c0-0.461,0.373-0.834,0.833-0.834
                        s0.833,0.373,0.833,0.834v17.924C4.661,42.22,4.287,42.593,3.827,42.593L3.827,42.593z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </v-flex>
                <v-flex xs12 blue--text mb-5>
                  <a :href="agreementUrl" :download="agreementFilename" target="_blank">
                    <strong>Download a copy of your membership agreement</strong>
                  </a>
                </v-flex>
              </v-layout>
              <v-flex
                xs12
                mb-3
              >Soon you will get an email to set up your property documents. In the meantime, if you have any questions you can email us at compliance@tenantevaluation.com or simply fill out the following form.</v-flex>
              <v-flex xs12 mb-5>
                <v-textarea
                  auto-grow
                  v-model="comments"
                  box
                  label="Enter your message here"
                  value
                  mb-0
                ></v-textarea>
              </v-flex>
            </v-container>
          </v-form>
          <v-btn
            large
            depressed
            color="primary"
            class="btn-toolbar"
            @click="submitComments"
            v-if="comments"
          >Send Comments</v-btn>
          <v-btn large depressed color="primary" class="btn-toolbar" to="/properties" v-else>Done</v-btn>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="showDialog" width="800">
      <v-card class="dialogTEV">
        <v-card-title class="headline lighten-2" primary-title>
          {{dialogTitle}}
          <v-spacer></v-spacer>
          <v-btn color="white" flat icon @click="showDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-xs-center pa-5" v-if="isLoading">
          <v-progress-circular :size="50" color="primary" indeterminate />
        </v-card-text>
        <v-card-text v-html="dialogBody" v-else />
        <v-divider></v-divider>
        <v-card-actions v-if="!isLoading">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--v-btn depressed color="primary" @click="blSearch">Blacklist Search Test</v-btn-->
  </v-card-text>
</template>
<script>
import axios from "axios";
import config from '@/config';
import loadScript from '@/utils/loadScript';
import CheckMark from "../../components/CheckMark";
import Validator from "../../libs/validator";
import TeSignaturePad from "../../components/TeSignaturePad";
import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../../libs/constants";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  name: "SignAndAgreeStep",
  props: {
    step: Number
  },
  components: {
    CheckMark,
    TeSignaturePad
  },
  data() {
    const validator = new Validator();
    // var sectionsDisablePricing = sectionsEnablePricing;
    // sectionsEnablePricing.push(
    //       ["ACH service (If applicable)", "ach_service"]);
    return {
      isAuthSign: null,
      hasSign: false,
      isLoading: false,
      isSubmitting: false,
      showProposal: false,
      showAgreement: false,
      showPreview: false,
      showDialog: false,
      signError: false,
      dialogTitle: "",
      dialogBody: "",
      sections: [
        ["FCRA Acknowledgment", "fcra_acknowledgment"],
        ["End User License Agreement", "enduseragreement"],
        ["Access Security Requirements", "access_security_requirements"],
        ["Equifax Data Security", "equifax_data_security"],
        [
          "Equifax Qualified Subscriber Terms and Conditions",
          "equifax_qualified_subscriber_terms"
        ],
        [
          "Credit Scoring Services Agreement",
          "credit_scoring_services_agreement"
        ],
        [
          "Notice to Users of Consumer Reports",
          "notice_users_consumer_reports"
        ],
        ["End User Certification of Compliance", "enuser_certf_compliance"],
        [
          "Vermont Fair Credit Reporting Contact Certification",
          "vermont_fair_credit_reporting_contact_certific"
        ]
      ],
      form: {
        signerEmail: null,
        signerName: null,
        signerTitle: null,
        signingDate: null,
        signature: null,
        isBlacklist: null,
        blacklistNotes: null,
        disablePricingInformation: null
      },
      rules: {
        required: [validator.rules.required()]
      },
      agreementUrl: null,
      agreementFilename: "Tenant_Evaluation_Agreement.pdf",
      previewUrl: null,
      complete: false,
      comments: null
    };
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp",
      currentUser: "currentUser"
    })
  },
  watch: {
    isAuthSign(val) {
      this.form.signerEmail = null;
      this.form.signerName = null;
      this.form.signerTitle = null;
      this.form.signature = null;
      this.$refs.form.reset();
      this.isAuthSign = val;
      this.setSigningDate();
    },
    complete(newVal) {
      if (newVal) {
        this.loadReviews();
      }
    }
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    loadReviews() {
      loadScript(`${config.reviewsWidgetUrl}/env.js`, () => {
        loadScript(`${config.reviewsWidgetUrl}/widget/widget.js`, () => {
          if (window.reviewsFacesWidget) {
            window.reviewsFacesWidget.load({
              jwt: localStorage.token,
              label: 'How was your experience?',
              type: 'CLIENTEV',
              step: 'ONBOARD_AGREEMENT',
              position: 'bottom',
              showtime: 10,
              showtimeaftercompleted: 1,
              data: `Property ID: ${this.currentApp.propertyId}`,
              dataid: this.currentApp.propertyId,
              google: false,
              feedback: true,
              localstorage: true,
              lskey: this.currentApp.propertyId,
            });
          }
        });
      });
    },
    async blSearch() {
      const _this = this;
      const blFields = [
        "propertyName",
        "street",
        "city",
        "propertyPhone",
        "einNumber",
        "pmFirstName",
        "pmLastName",
        "pmPhone",
        "authUserEmail"
      ];
      const postData = {};
      blFields.forEach(key => {
        postData[key] = _this.currentApp[key];
      });
      return await axios.post(
        "https://clientev.geetsi.es/api/blacklist-search",
        postData
      );
    },
    async inviteSigner() {
      const _this = this;
      if (!this.$refs.form.validate() || this.isLoading) {
        return;
      }
      this.isLoading = true;
      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)

      this.$http
        .post("/manager/properties/invite-signer", this.currentApp)
        .then(() => {
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](_this.form);
          _this.$events.$emit("showSnackbar", {
            message: "Invitation sent!",
            color: "green"
          });
          _this.loadReviews();
          _this.$router.push("/properties");
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError("An error occurred, please try again later.");
        })
        .finally(() => {
          _this.isLoading = false;
        });
    },
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    async submitForm() {
      const _this = this;

      if (this.isSubmitting) {
        return;
      }

      if (this.isAuthSign === null) {
        return this.alertError(
          "Please confirm if you are authorized to sign this agreement."
        );
      }

      if (this.isAuthSign === false) {
        return this.inviteSigner();
      }

      if (!this.$refs.form.validate() || this.isLoading) {
        return;
      }
      if (!this.form.signature) {
        this.signError = true;
        return;
      }
      this.isLoading = true;
      this.isSubmitting = true;

      this.form.signerEmail = this.currentUser.email;

      this[ACTION_TYPES.UPDATE_APP_PROPERTIES](this.form)

      // blacklist search
      /*
      const bl = await this.blSearch()
      if(bl&&bl.data&&bl.data.status == 'FOUND') {
        this[ACTION_TYPES.UPDATE_APP_PROPERTIES]({
          isBlacklist: true,
          blacklistNotes: JSON.stringify(bl.data)
        })
      }
      */

      this.$http
        .post("/manager/properties/finish-pending", this.currentApp)
        .then(response => {
          _this.agreementUrl = response.data.result;
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](_this.form);
          _this.showAgreement = true;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError("An error occurred, please try again later.");
        })
        .finally(() => {
          _this.isLoading = false
          _this.isSubmitting = false
        })
    },
    onSignatureChange(sign) {
      if (sign.isEmpty) {
        this.hasSign = false;
        this.form.signature = null;
      } else {
        this.hasSign = true;
        this.form.signature = sign.data;
      }
    },
    fillForm() {
      for (let key in this.form) {
        if (this.currentApp[key] != null) {
          this.form[key] = this.currentApp[key];
        }
      }
    },
    openDialog(el) {
      const _this = this;
      this.isLoading = true;
      this.dialogTitle = el[0];
      this.dialogBody = "";
      this.showDialog = true;
      axios
        .get("/terms/" + el[1] + ".html")
        .then(response => {
          _this.dialogBody = response.data;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => (this.isLoading = false));
    },
    downloadAgreement() {
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = this.agreementUrl;
      a.setAttribute("download", "Tenant_Evaluation_Agreement.pdf");
      a.click();
      document.body.removeChild(a);
    },
    previewAgreement() {
      const _this = this;
      this.isLoading = true;

      this.$http
        .post("/manager/properties/preview-agreement", this.currentApp)
        .then(response => {
          _this.previewUrl = response.data.result;
          _this[ACTION_TYPES.UPDATE_APP_PROPERTIES](_this.form);
          _this.showPreview = true;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError("An error occurred, please try again later.");
        })
        .finally(() => {
          _this.isLoading = false;
        });
    },
    submitComments() {
      const _this = this;
      this.$http
        .post("/manager/properties/comment", {
          id: this.currentApp.propertyId,
          description: this.comments
        })
        .then(() => {
          _this.$events.$emit("showSnackbar", {
            message: "Message sent!",
            color: "green"
          });
          this.$router.push("/properties");
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    setSigningDate() {
      this.form.signingDate = new Date().toISOString().substr(0, 10);
    }
  },
  events: {
    continue(e) {
      if (e.step == this.step) {
        this.submitForm();
      }
    }
  },
  mounted() {
    this.fillForm();
    if(!this.currentApp.disablePricingInformation) {
      this.sections.push(["ACH service (If applicable)", "ach_service"]);
    }
    if (this.form.signature) {
      this.$refs.signaturePad.fromDataURL(this.form.signature);
      this.hasSign = true;
    }
  }
};
</script>
<style>
.row-terms {
  padding: 2px 8px;
  border-radius: 4px;
}

.row-terms:hover {
  background-color: #fff;
}

#app .dialog-agreement {
  padding: 56px 0 !important;
  height: 100%;
  background-color: #f8f8f8;
}

.dialog-agreement .v-toolbar__content {
  height: 56px !important;
}

.dialog-agreement iframe {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0 !important;
}

.dialog-agreement .btn-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 56px !important;
  width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.img-border {
  border: 1px solid #000 !important;
}
</style>
