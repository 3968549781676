var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-text",
    { staticClass: "pt-0" },
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-container",
            { staticClass: "credit-score" },
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("h2", { staticClass: "step-title" }, [
                  _vm._v("Important Property Requirements"),
                ]),
              ]),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("p", [
                      _vm._v(
                        "\n            Please include any important property requirements you want to inform the applicants\n            "
                      ),
                      _c("strong", [_vm._v("before they apply")]),
                      _vm._v(". For example:\n          "),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Allowed Moving Days: Monday, Friday from 8 am to 5 pm"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "An interview with the board is also part of the application process to obtain approval."
                        ),
                      ]),
                      _c("li", [_vm._v("A credit score of 649 is required.")]),
                      _c("li", [
                        _vm._v(
                          "Common area security deposit of $2,000.00 made payable to the community is required, once tentatively approved."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Elevator deposit of $1,000.00 required for move in."
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          counter: 1100,
                          rules: [
                            (v) =>
                              !v ||
                              (v && v.length <= 1100) ||
                              "Notes must be 1100 characters or less",
                          ],
                          autofocus: _vm.$isDesktop(),
                          box: "",
                          label: "Add notes here (Optional)",
                        },
                        model: {
                          value: _vm.form.propertyNotes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "propertyNotes", $$v)
                          },
                          expression: "form.propertyNotes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticStyle: { "padding-top": "25px" } }),
              _vm.currentApp.allowLeases
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("strong", [_vm._v("Minimum Lease Length")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-1": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              label: "Add number of months",
                              rules: _vm.rules.required,
                              required: "",
                              box: "",
                            },
                            model: {
                              value: _vm.form.leaseMin,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "leaseMin", $$v)
                              },
                              expression: "form.leaseMin",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider", { staticStyle: { "padding-top": "25px" } }),
              _c(
                "div",
                { staticClass: "mb-4", staticStyle: { "padding-top": "25px" } },
                [_c("h3", [_vm._v("Credit Score")])]
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mb-3": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-3": "" } },
                        [
                          _c("strong", [
                            _vm._v(
                              "Does your property require a minimum credit score?"
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              on: { change: _vm.changeRequire },
                              model: {
                                value: _vm.form.requireMinScore,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "requireMinScore", $$v)
                                },
                                expression: "form.requireMinScore",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "Yes",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  color: "blue",
                                  label: "No",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.requireMinScore
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("p", [
                            _vm._v(
                              "What will be the minimum average credit score for an applicant to be approved in your property"
                            ),
                          ]),
                          _c("p", { staticClass: "grey--text" }, [
                            _vm._v(
                              "Move the bar to setup the score or enter it manually"
                            ),
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    lg4: "",
                                    md4: "",
                                    sm12: "",
                                    "offset-md4": "",
                                    "offset-lg4": "",
                                    "offset-sm0": "",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Credit Score",
                                      "single-line": "",
                                      required: "",
                                      box: "",
                                      type: "number",
                                      max: _vm.maxCreditScore,
                                      readonly: "",
                                    },
                                    model: {
                                      value: _vm.form.creditScore,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "creditScore", $$v)
                                      },
                                      expression: "form.creditScore",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    lg8: "",
                                    md8: "",
                                    sm12: "",
                                    "offset-md2": "",
                                    "offset-lg2": "",
                                    "offset-sm0": "",
                                  },
                                },
                                [
                                  _c("v-slider", {
                                    attrs: {
                                      min: _vm.minCreditScore,
                                      max: _vm.maxCreditScore,
                                    },
                                    model: {
                                      value: _vm.form.creditScore,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "creditScore", $$v)
                                      },
                                      expression: "form.creditScore",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-5": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    lg8: "",
                                    md8: "",
                                    sm12: "",
                                    "offset-md2": "",
                                    "offset-lg2": "",
                                    "offset-sm0": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-content-space-around": "",
                                        "text-xs-center": "",
                                      },
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v("Fair"),
                                      ]),
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v("Good"),
                                      ]),
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v("Very Good"),
                                      ]),
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _vm._v("Excellent"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }