<template>
  <v-app>
    <tev-navbar :back="true" v-on:back="doBack" title="Back" />
    <v-content class="form-view">
      <v-layout row wrap pt-4>
        <v-flex sm12 md3>
          <v-navigation-drawer class="steps" permanent>
            <v-toolbar flat>
              <v-list>
                <v-list-tile>
                  <v-list-tile-title>STEPS</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-toolbar>

            <v-list dense class="pt-0">
              <v-list-tile v-for="(item,index) in steps"
                :key="item.id"
                @click="gotoStep(item)"
                :disabled="item.disabled"
                :class="[index == step-1?'tile_active':'']"

              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.name }} </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-navigation-drawer>
        </v-flex>
        <v-flex sm12 md9>
          <v-layout row wrap pt-4 v-if="step<=totalSteps">
            <v-card-text class="pt-0">
              <v-container>
                <div class="mb-4">
                  <h2 class="property-title">{{ propertyName }}</h2>
                </div>
              </v-container>
            </v-card-text>
          </v-layout>
          <v-window v-model="step" :touchless="true">
            <v-window-item
              v-for="(comp, index) in steps"
              :key="comp.id"
              :value="index+1"
              :lazy="true"
            >
              <component v-bind:is="comp.id" :step="index+1"></component>
            </v-window-item>
          </v-window>
        </v-flex>
      </v-layout>
    </v-content>
    <v-card class="form-card">
      <v-footer absolute dark class="form-footer">
        <v-layout row justify-center>
          <v-flex xs1 sm3 text-xs-center>
            <v-btn class="form-icon hidden-sm-and-down" icon to="/">
              <img src="../assets/TE-Icons.svg" height="20" width="20" />
            </v-btn>
          </v-flex>
          <v-flex xs11 sm9 text-xs-center>
            <v-btn depressed large @click="doBack" :disabled="step === 1" class="btn-back btn-toolbar">Back</v-btn>
            <v-btn
              large
              depressed
              color="primary"
              @click="nextStep"
              :loading="isLoading"
              class="btn-toolbar"
              v-bind:class="{'blue--text': isLoading, 'white--text': !isLoading}"
              :disabled="isLoading"
              v-text="step==totalSteps?'Submit':'Continue'"
            ></v-btn>
          </v-flex>
        </v-layout>
      </v-footer>
    </v-card>
  </v-app>
</template>
<script>
import config from '@/config';

import { mapGetters, mapActions } from "vuex";
import { ACTION_TYPES } from "../libs/constants";

import CommunityInformationStep from "../components/form/CommunityInformationStep";
import CreditInformationStep from "../components/form/CreditInformationStep";
import ComplianceStep from "../components/form/ComplianceStep";
import PetsInformationStep from "../components/form/PetsInformationStep";
import DocumentsStep from "../components/form/DocumentsStep";
import PropertyRequirementsStep from "../components/form/PropertyRequirementsStep";
import CommunityFundingStep from "../components/form/CommunityFundingStep";
import SignAndAgreeStep from "../components/form/SignAndAgreeStep";

export default {
  name: "App",
  components: {
    CommunityInformationStep,
    CreditInformationStep,
    ComplianceStep,
    PetsInformationStep,
    DocumentsStep,
    PropertyRequirementsStep,
    CommunityFundingStep,
    SignAndAgreeStep
  },
  filters: {
    pretty(value) {
      return JSON.stringify(value, null, 2);
    }
  },
  data: () => ({
    NODE_ENV: config.nodeEnv,
    isLoading: false,
    step: 1,
    totalSteps: 0,
    steps: [],
  }),
  watch: {
    step(newStep, oldStep) {
      this.checkMaxStep(newStep-1)
    }
  },
  computed: {
    ...mapGetters({
      currentApp: "currentApp"
    }),
    propertyId() {
      return this.currentApp.propertyId;
    },
    propertyName() {
      return this.currentApp.propertyName;
    },
    allowLeases() {
      return this.currentApp.allowLeases;
    },
    allowPurchases() {
      return this.currentApp.allowPurchases;
    },
    allowGuests() {
      return this.currentApp.allowGuests;
    },
    fundAccount() {
      return this.currentApp.fundAccount;
    },
  },
  created() {
    //this.checkCurrentLogin()
    this.checkPropertyId();
  },
  updated() {
    //this.checkCurrentLogin()
    this.checkPropertyId();
  },
  methods: {
    ...mapActions([ACTION_TYPES.UPDATE_APP_PROPERTIES]),
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (
        !this.currentUser ||
        !this.currentUser.isLoggedIn ||
        !localStorage.token ||
        now >= this.currentUser.expire
      ) {
        this.$router.push("/login?redirect=" + this.$route.path);
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    },
    checkPropertyId() {
      const id = this.$route.params.propertyId;
      if (id != this.currentApp.propertyId) {
        this.$router.push("/welcome/" + id);
      }
    },
    historyPush() {
      history.pushState(
        null,
        null,
        "/form/" + this.propertyId + "/" + this.step
      );
    },
    doBack() {
      if (this.step > 1) {
        this.step--;
        this.historyPush();
        window.scrollTo(0, 0);
      } else {
        this.$router.push("/properties");
      }
    },
    doContinue() {
      this.step++;
      this.historyPush();
      window.scrollTo(0, 0);
    },

    nextStep() {
      this.$events.$emit("continue", {
        step: this.step
      });
    },
    gotoStep(step) {
      this.step = this.findStepById(step.id) + 1;
      window.scrollTo(0, 0);
    },
    loadWindow() {
      const _this = this;
      setTimeout(function() {
        _this.isLoading = true;
        setTimeout(function() {
          _this.isLoading = false;
          _this.doContinue();
        }, 100);
      }, 100);
    },
    findStepById(id) {
      for (let key in this.steps) {
        if (this.steps[key].id == id) {
          return parseInt(key);
        }
      }
      return null;
    },
    mountSteps() {
      this.totalSteps = 0;
      this.steps = [];

      this.steps.push({
        id: "CommunityInformationStep",
        name: "Community Information",
        disabled:false,
        required: ['propertyName','businessOrigin','entityType','einNumber','street','city','state','zipcode','businessYears','unitsNumber','propertyPhone'],
      });
      this.steps.push({
        id: "CreditInformationStep",
        name: "Credit Information",
        disabled:false,
        required: ['creditReportUse','agreeNoResell','getDecisionLetter'],
      });
      this.steps.push({
        id: "ComplianceStep",
        name: "Compliance",
        disabled:false,
        required: ['vtComplianceInfo.understandVCRS', 'vtComplianceInfo.agreeCommonSignature'],
      });
      this.steps.push({
        id: "PetsInformationStep",
        name: "Pets information",
        disabled:false,
        required: ['leaseAllowPets','purchaseAllowPets','guestAllowPets'],
      });
      this.steps.push({
        id: "DocumentsStep",
        name: "Document Collection",
        disabled:false,
        required: [],
      });
      this.steps.push({
        id: "PropertyRequirementsStep",
        name: "Property Requirements",
        disabled:false,
        required: ['requireMinScore'],
      });
      if(!this.currentApp.disablePricingInformation) {
        this.steps.push({
          id: "CommunityFundingStep",
          name: "Community Funding",
          disabled:false,
          required: ['agreeACHTerms','accountsPayableName','accountsPayableEmail','splitManagement','splitCommunity'],
        });
      }
      this.steps.push({
        id: "SignAndAgreeStep",
        name: "Sign and Agree",
        disabled:false,
        required: [],
      });

      this.totalSteps = this.steps.length;
      this.checkMaxStep(0);
    },
    checkMaxStep(maxCheck){
      //this.currentApp["creditReportUse"] = null
      try {
        if (maxCheck==0)
          maxCheck = this.steps.length;

        let stepLen = Math.min(maxCheck, this.steps.length-1);
        let indexDisabled = -1
        for (var i = 0; i < stepLen; i++) {
          if (indexDisabled>=0) break;
          this.steps[i].disabled = false;
          for (let key in this.steps[i].required) {
            const fields = this.steps[i].required[key].split(".")
            if (fields.length==1) {
              if (this.currentApp[fields[0]] == null){
                //console.log("indexDisabled1", fields[0])
                indexDisabled = i + 1
                break
              }
            } else if (fields.length==2) {
              if (this.currentApp[fields[0]] == null || !this.currentApp[fields[0]][fields[1]]){
                //console.log("indexDisabled2", fields[0])
                indexDisabled = i + 1
                break
              }
            }
          }
        }
        if (indexDisabled > 0) {
          for (var k = indexDisabled; k < this.steps.length; k++) {
            this.steps[k].disabled = true;
          }
        }
      }
      catch(ex){
        console.error(ex);
      }
    }
  },

  mounted() {
    const _this = this;

    this.checkPropertyId();
    this.mountSteps();
    this.step = parseInt(this.$route.params.step) || 1;
    this.$events.$on("success", () => {
      _this.loadWindow();
    });
  }
};
</script>
<style>
.form-card {
  padding-top: 70px !important;
}

.form-footer {
  height: 70px !important;
}

.form-buttons {
  margin-bottom: 10px;
}

.form-icon {
  width: 40px !important;
}

.form-buttons .v-btn {
  width: 190px;
}

.btn-back {
  background-color: #f8f8f8 !important;
  color: #000 !important;
}

.container {
  padding: 0px 16px !important;
}

.steps {
  background-color: transparent !important;
  padding-left: 30px;
}

.steps .v-toolbar {
  background-color: transparent !important;
}

@media only screen and (min-height: 959px) {
  .v-window-item .v-card__text {
    min-height: 400px;
  }
}

@media only screen and (max-width: 959px) {
  .form-buttons {
    width: 100% !important;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 56px;
  }
  .btn-toolbar {
    width: 160px!important;
    padding: 0px!important;
    /*margin: 0px!important;
    width: 100% !important;
    margin: 0;
    height: 56px;*/
  }

  .v-window-item .v-card__text {
    margin-bottom: 60px;
  }
}


.form-view .v-list__tile__content .v-list__tile__title {
  display:list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.form-view .tile_active {
  color: #2196F3;
}
</style>
